import React from "react";

const Contact = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Intro */}

      <header
        className="header-page header-fullscreen header-page--gutterBottom jarallaxVideo reveal"
        style={{ color: "white" }}
        data-jarallax-video="mp4:https://res.cloudinary.com/dopcyrucs/video/upload/v1672487715/video/Contact_r9ovwc.mp4"
      >
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "absolute",
            background: "#1B1A17",
            opacity: "0.5"
          }}
        />
        <div className="container flex-center-end js-opacity">
          {/* Title */}
          <div className="flex-center">
            <div className="flex-center__inner">
              <h1 className="title title--display-1 js-lines-l">
                Our doors are open, come talk to us.{" "}
                {/*<span class="text--red">collaborate</span> */}
              </h1>
            </div>
          </div>
          {/* /Title */}
          {/* <div class="text-center text-sm-left">
				<a class="btn-link btn-link--circle-right" href="#">Contact Us<i class="circle circle--white circle--right icon-down-open"></i></a>
			</div> */}
        </div>
      </header>
      {/* /Intro */}
      <div className="row connect" id="start">
        <div className="col-12 col-lg-12">
          <h2
            className="title title--h3 text-lg-center js-lines"
            style={{ marginTop: "1.71rem", color: "#46c8fb" }}
          >
            CONNECT WITH US
          </h2>
          <p className="paragraph " style={{ textAlign: "center" }}>
            How are you feeling? We would like to hear from you.
          </p>
        </div>
      </div>

      <div className="container-fluid container-cutout mb--2">
        {/* <h1 class="title title--h1 js-lines-l" style="margin-bottom: 0.4rem;">What We Do.</h1> */}
        <div className="row">
          {/* itemCard */}
          <div className="col-12 col-sm-12 col-lg-6 col-xl-4 itemService con">
            <div className="itemCard__imageWrap">
              <div className="emoji smile">
                <figure className="face">
                  <span className="eyes">
                    <span className="eye" />
                    <span className="eye" />
                  </span>
                  <span className="mouth"></span>
                </figure>
              </div>
            </div>
            <div className="itemCard__header js-opacity ">
              <h3
                className="title title--h5 itemCard__title js-lines"
                style={{ textAlign: "center" }}
              >
                Decided but need to find out more?
              </h3>
            </div>
          </div>
          {/* /itemCard */}
          {/* itemCard */}
          <div className="col-12 col-sm-12 col-lg-6 col-xl-4 itemService con">
            <div className="itemCard__imageWrap">
              <div className="emoji speechless">
                <figure className="face">
                  <span className="eyes">
                    <span className="eye" />
                    <span className="eye" />
                  </span>
                  <span className="mouth"></span>
                </figure>
              </div>
            </div>
            <div className="itemCard__header js-opacity ">
              <h5
                className="title title--h5 itemCard__title js-lines"
                style={{ textAlign: "center" }}
              >
                Somewhat decided but I need some more convincing
              </h5>
            </div>
          </div>
          {/* /itemCard */}
          {/* itemCard */}
          <div className="col-12 col-sm-12 col-lg-6 col-xl-4 itemService con">
            <div className="itemCard__imageWrap">
              <div className="emoji sad">
                <figure className="face">
                  <span className="eyes">
                    <span className="eye" />
                    <span className="eye" />
                  </span>
                  <span className="mouth tounge"></span>
                </figure>
              </div>
            </div>
            <div className="itemCard__header js-opacity con">
              <h5
                className="title title--h5 itemCard__title js-lines"
                style={{ textAlign: "center" }}
              >
                Undecided
              </h5>
            </div>
          </div>
          {/* /itemCard */}
          <div className="col-12 col-lg-12">
            <p
              className="paragraph "
              style={{ textAlign: "center", fontSize: 20 }}
            >
              Tell us! We’ll help make the right choice for you.
            </p>
          </div>
        </div>
      </div>

      <section
        className="section section__hello section_no-space-bottom"
        id="hello"
        style={{ marginBottom: "4.4rem" }}
      >
        <div className="row">
          <div className="col-12 col-lg-12">
            <h2
              className="title title--h3 text-lg-center js-lines"
              style={{ marginTop: "1.71rem" }}
            >
              OFFICES
            </h2>
            <p
              className="paragraph "
              style={{ textAlign: "center", fontSize: "larger" }}
            >
              Stop by, let's discuss your business growth over hot coffee/tea
            </p>
          </div>
        </div>
        <div className="row text-lg-center">
          {/* <ul className="col-12 col-md-4 col-lg-4 col-xl-4 listContact list-unstyled js-lines">
            <li>
              <h6 className="title title--h6">Nigeria Address</h6>
            </li>
            <li style={{ padding: "0px 50px" }}>
              315b Akin Ogunlewe Rd, Victoria Island, Lagos <br />
              &amp;
              <br />
              No. 1, Adekunle Owobiyi Close, Ogba, Lagos, Nigeria
            </li>
            <li>
              <strong>Tel:</strong> (+234) 901 9750589
            </li>
            <li>
              <strong>Email:</strong> nigeria@dmtca.com
            </li>
          </ul> */}
          {/* <ul className="col-12 col-md-4 col-lg-4 col-xl-4 listContact list-unstyled js-lines">
            <li>
              <h6 className="title title--h6">Ghana Address</h6>
            </li>
            <li style={{ padding: "0px 50px" }}>
              No.4, Ashikai Trebi Link East Legon, Accra, Ghana
            </li>
            <li>
              <strong>Tel:</strong> (+233) 266083027
            </li>
            <li>
              <strong>Email:</strong> ghana@dmtca.com
            </li>
          </ul> */}
          <ul className="col-12 col-md-12 col-lg-12 col-xl-12 listContact list-unstyled js-lines">
            <li>
              <h6 className="title title--h6">Multiply Digital</h6>
            </li>
            <li style={{ padding: "0px 50px" }}>
              457, 3rd Floor, DIT Road, West &
              <br /> Rampura, Dhaka-1219
            </li>
            <li>
              <strong>Tel:</strong> (+880) 1768729111
            </li>
            <li>
              <strong>Email:</strong> info@multiplydigital.net
            </li>
          </ul>
        </div>
        <div
          className="text-decoration"
          data-100-start="transform[swing]:translateY(100px)"
          data--800-top="transform[swing]:translateY(-100px)"
        >
          Contact Us
        </div>
      </section>

      <section></section>

      <article id="start" className="caption-singles container">
        <div className="row">
          {/* Intro */}
          <header
            className="header-page  js-opacity"
            style={{ marginTop: "1vh" }}
          >
            <div className="container">
              {/* Title */}
              <h5 className="title title--overhead title--tail">
                Email Us On Any Of
              </h5>
              <h1 className="title title--display-1 js-lines">
                The Email Addresses Below That Meets Your Needs
              </h1>
              {/* /Title */}
            </div>
          </header>
          {/* /Intro */}
          <div className="col-12 col-lg-6 ">
            <div
              className="description noGutters-Bottom js-block"
              style={{ marginBottom: "20px !important" }}
            >
              <p className="paragraph noGutters-Bottom">
                For new business, sales or collaborative working opportunities -
                <span>
                  <a
                    href="mailto:someone@example.com?Subject=Hello"
                    target="_top"
                  >
                    sales@dmtca.com
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div
              className="description noGutters-Bottom js-block"
              style={{ marginBottom: "20px !important" }}
            >
              <p className="paragraph noGutters-Bottom">
                Want to be a part of our digital revolution -
                <span>
                  <a href="mailto:career@dmtca.com?Subject=Hello" target="_top">
                    career@dmtca.com
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="description noGutters-Bottom js-block">
              <p className="paragraph noGutters-Bottom">
                Need help? Please write to us at -<br />
                <span>
                  <a
                    href="mailto:support@dmtca.com?Subject=Hello"
                    target="_top"
                  >
                    support@dmtca.com
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="description noGutters-Bottom js-block">
              <p className="paragraph noGutters-Bottom">
                Not sure about who to email? Please direct all general enquiries
                to -
                <span>
                  <a href="mailto:info@dmtca.com?Subject=Hello" target="_top">
                    info@dmtca.com
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </article>

      <div
        id="start"
        className="flex-conteiner-fluid h--100"
        style={{ marginTop: "5rem" }}
      >
        <div className="row flex-fill justify-content-center align-items-md-center">
          <div className="col-12 col-lg-10 col-xl-8 contect-block js-block">
            <h2
              className="title title--h3 text-lg-center js-lines"
              style={{ marginBottom: "5rem" }}
            >
              Let Us Help You Grow in Digital
            </h2>
            <form
              id="contact-form"
              className="contact-form"
              data-toggle="validator"
              noValidate="true"
            >
              <div className="container container_md">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="inputText"
                        id="frmname"
                        name="frmname"
                        placeholder="Full Name"
                        required="required"
                        autoComplete="off"
                        oninvalid="setCustomValidity('Fill in the field')"
                        onkeyup="setCustomValidity('')"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="inputText"
                        id="frmemail"
                        name="frmemail"
                        placeholder="Email Address"
                        required="required"
                        autoComplete="off"
                        oninvalid="setCustomValidity('Email is incorrect')"
                        onkeyup="setCustomValidity('')"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="number"
                        className="inputText"
                        id="frmphone"
                        name="frmphone"
                        placeholder="Phone Number"
                        required="required"
                        autoComplete="off"
                        oninvalid="setCustomValidity('Fill in the field')"
                        onkeyup="setCustomValidity('')"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="inputText"
                        id="frmlocation"
                        name="frmlocation"
                        placeholder="Present Location 
"
                        required="required"
                        autoComplete="off"
                        oninvalid="setCustomValidity('Fill in the field')"
                        onkeyup="setCustomValidity('')"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="inputText"
                        id="frmdesignation"
                        name="frmdesignation"
                        placeholder="Position Held In Company"
                        required="required"
                        autoComplete="off"
                        oninvalid="setCustomValidity('Fill in the field')"
                        onkeyup="setCustomValidity('')"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        pattern="((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in)(\/[^\s]*)?"
                        className="inputText"
                        id="frmwebsite"
                        name="frmwebsite"
                        placeholder="Company Website"
                        required="required"
                        autoComplete="off"
                        oninvalid="setCustomValidity('Website url not valid. ')"
                        onkeyup="setCustomValidity('')"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="inputText"
                        id="frmmessage"
                        name="frmmessage"
                        placeholder="Your Message"
                        required="required"
                        autoComplete="off"
                        oninvalid="setCustomValidity('Fill in the field')"
                        onkeyup="setCustomValidity('')"
                      />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div
                    className="col-12 col-md-12"
                    style={{ textAlign: "center" }}
                  >
                    <button type="submit" className="btn2 disabled">
                      Send Message
                    </button>
                  </div>
                  <div className="col-12 col-md-12 text-md-right">
                    <div id="validator-contact" className="_hidden">
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
