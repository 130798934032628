import React from "react";
import CircleCard from "../../components/homePage/CircleCard";

const Service = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Intro */}
      <header
        className="header-page header-fullscreen header-page--gutterBottom jarallaxVideo reveal"
        style={{ color: "white" }}
        data-jarallax-video="mp4:https://res.cloudinary.com/www-dmtca-agency/video/upload/v1602248135/services_mlypql.mp4"
      >
        <div
          style={{
            height: "100vh",
            width: "100%",
            position: "absolute",
            background: "#000000cc",
          }}
        />
        <div className="container flex-center-end js-opacity">
          {/* Title */}
          <div className="flex-center">
            <div className="flex-center__inner">
              <div className="container">
                {/* Title */}
                {/* <h6 class="title title--overhead title--tail">Career</h6> */}
                <h1 className="title title--display-1 js-lines">
                  We strongly cement your brands presence, brick by brick.
                </h1>
                {/* /Title */}
              </div>
            </div>
          </div>
          {/* /Title */}
        </div>
      </header>
      {/* /Intro */}
      {/* Process */}
      <article id="start" className="caption-singles container">
        <div className="row">
          {/* Intro */}
          <header className="header-page  js-opacity">
            <div className="container">
              {/* Title */}
              <h6 className="title title--overhead title--tail">
                CAPABILITIES
              </h6>
              <h1 className="title title--display-1 js-lines">
                Our services are composed of three different platforms:
              </h1>
              {/* /Title */}
            </div>
          </header>
          {/* /Intro */}
          <div className="col-12 col-lg-9 m-l">
            <div className="description noGutters-Bottom js-block">
              <p className="paragraph noGutters-Bottom">
                The present world is more connected in an intensity that nobody
                ever fully comprehended. Creating an online brand presence is a
                mandatory requirement for any business that wants to see itself
                grow and prosper. From building the brand up from scratch to
                maintain it anc cater to its growth with meaningful contents and
                Return oriented approach, we cater to your business’s 360°
                marketing necessities.
                <strong
                  style={{
                    fontSize: 20,
                    fontWeight: 800,
                    letterSpacing: 1,
                    color: "#56b7be",
                  }}
                >
                  {" "}
                  Digital Marketing, Website development , Event Management{" "}
                </strong>
                are the three platforms of us.
              </p>
            </div>
          </div>
        </div>
      </article>
      {/* /Process */}
      {/* Services */}
      {/* <article class="caption-single container">
  <div class="row">
		    <div class="col-12 col-lg-3">
			    <h4 class="title title--overhead js-lines">Services</h4>
			</div>
			<div class="col-12 col-lg-9">
				<h2 class="title title--h4 js-lines">Main areas of expertise</h2>
			</div>
		</div>
	</article> */}
      {/* 
  circle card show here */}
      <CircleCard />

      <div className="container-fluid container-cutout mb--2">
        <div className="row "></div>
        <article id="start" className="caption-singles container">
          <div className="row">
            {/* Intro */}
            <header className="header-page m-t">
              <div className="container">
                {/* Title */}
                <h6 className="title title--overhead title--tail" />
                <h1 className="title title--display-1 js-lines">
                  STRATEGY &amp; THINKING
                </h1>
                {/* /Title */}
                <div className="col-12 col-lg-9 m-l">
                  <div className="description noGutters-Bottom js-block">
                    <p className="paragraph noGutters-Bottom">
                      Listening
                      <br />
                      Social Sentiment Mapping
                      <br />
                      Persona Development
                      <br />
                      Customer Journey Mapping
                      <br />
                      Brand Digital Present Audit <br />
                      Competition Analysis and Benchmarking
                      <br />
                      Integrated Digital Marketing Strategy – Content, Inbound,
                      B2C, B2B, ABM
                      <br />
                      Standalone Strategy - Social Media – Always On and
                      Campaign, Paid Media, SEO, ASO, Email – Segmented and Drip
                      Campaign, UI &amp; UX, Website, Lead Generation,
                      Conversion Rate Optimization <br />
                      Creative Ideation – Big Idea
                    </p>
                  </div>
                </div>
              </div>
            </header>
            {/* /Intro */}
          </div>
        </article>
        <div className="row services-row">
          {/* itemCard */}
          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 itemService">
            <div className="itemCard__imageWrap reveal">
              {/* <img class="itemCard__image lazyload" src="https://dmtca.com/img/servicesBrand.jpg" alt=""> */}
            </div>
            <div className="itemCard__header js-opacity  border-r">
              <h3 className="title title--h5 itemCard__title js-lines">
                1. WEB &amp; APP DEVELOPMENT
              </h3>
              <div className="row">
                <div className="col-12 col-md- col-lg-6">
                  <ul className="list-unstyled js-block">
                    <li> We develop and deploy &amp; custom websites,</li>
                    <li> mobile apps,</li>
                    <li> providing solutions for small,</li>
                    <li> midsize and large companies</li>
                    <li> from different industries.</li>
                    <li> We have created dozens of</li>
                    <li> high-profile websites &amp; and mobile </li>
                    <li> apps for iOS,</li>
                    <li> Android during the </li>
                    <li>course of the</li>
                    <li>last few years</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <ul className="list-unstyled js-block">
                    <li> Our website and</li>
                    <li> mobile application devel- opment</li>
                    <li> process comprises the complete </li>
                    <li> software development</li>
                    <li> life-cycle, </li>
                    <li> from business </li>
                    <li> requirements analysis,</li>
                    <li> to applica- tion </li>
                    <li> definition and design,</li>
                    <li> testing,</li>
                    <li> imple- mentation </li>
                    <li> and support. </li>
                  </ul>
                </div>
              </div>
              {/* <a class="btn-link js-lines" href="services.html#">Know More <i class="icon-base icon-right-open"></i></a> */}
            </div>
          </div>
          {/* /itemCard */}
          {/* itemCard */}
          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 itemService">
            <div className="itemCard__imageWrap reveal">
              {/* <img class="itemCard__image lazyload" src="https://dmtca.com/img/servicesWeb.jpg" alt=""> */}
            </div>
            <div className="itemCard__header js-opacity  border-r">
              <h3 className="title title--h5 itemCard__title js-lines">
                2. BRAND IDENTITY
              </h3>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  {/* <h4 class="title title--h6 title--subtitle js-lines">MEDIA </h4> */}
                  <ul className="list-unstyled js-block">
                    <li>
                      {" "}
                      Whether up from scratch or making significant improvements
                      &amp; to a brand's resource to generate{" "}
                    </li>
                    <li> consumer based return from the various </li>
                    <li> segments through digital know-how </li>
                    <li> is the service that Multiply Digital </li>
                    <li> is distinctly proud of.</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  {/* <h4 class="title title--h6 title--subtitle js-lines">MEDIA </h4> */}
                  <ul className="list-unstyled js-block">
                    <li> We design and produce marketing.&amp; advertising </li>
                    <li> and promotional materials </li>
                    <li> that are tailor-made to our </li>
                    <li> client's needs to mark their</li>
                    <li> brand within the consumer's mind.</li>
                  </ul>
                </div>
              </div>
              {/* <a class="btn-link js-lines" href="services.html#">Know More <i class="icon-base icon-right-open"></i></a> */}
            </div>
          </div>
          {/* /itemCard */}
          {/* itemCard */}
          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 itemService">
            <div className="itemCard__imageWrap reveal">
              {/* <img class="itemCard__image lazyload" src="https://dmtca.com/img/servicesApp.jpg" alt=""> */}
            </div>
            <div className="itemCard__header js-opacity">
              <h3 className="title title--h5 itemCard__title js-lines">
                3. SOCIAL MEDIA
              </h3>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  {/* <h4 class="title title--h6 title--subtitle js-lines">CONVERSION & NURTURE
                      </h4> */}
                  <ul className="list-unstyled js-block">
                    <li> As your social </li>
                    <li> media marketing agency,</li>
                    <li> we creates social</li>
                    <li> media content out </li>
                    <li> of existing content </li>
                    <li> such as blog posts, </li>
                    <li> videos, and events. </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  {/* <h4 class="title title--h6 title--subtitle js-lines">CONVERSION & NURTURE
                      </h4> */}
                  <ul className="list-unstyled js-block">
                    <li> We add captions, upload videos, </li>
                    <li> add links, and determine </li>
                    <li> how and what will</li>
                    <li> get &amp;boosted through</li>
                    <li> advertising or other meth- ods.</li>
                    <li> Work with Analytics </li>
                    <li> and Reports.</li>
                  </ul>
                </div>
              </div>
              {/* <a class="btn-link js-lines" href="services.html#">Know More <i class="icon-base icon-right-open"></i></a> */}
            </div>
          </div>
          {/* /itemCard */}
          {/* itemCard */}
          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 itemService">
            <div className="itemCard__imageWrap reveal">
              {/* <img class="itemCard__image lazyload" src="https://dmtca.com/img/servicesApp.jpg" alt=""> */}
            </div>
            <div className="itemCard__header js-opacity">
              <h3 className="title title--h5 itemCard__title js-lines">
                4. CONTENT MARKETING
              </h3>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  {/* <h4 class="title title--h6 title--subtitle js-lines">CONVERSION & NURTURE
                      </h4> */}
                  <ul className="list-unstyled js-block">
                    <li> While developing a brand,</li>
                    <li> we try to get the best results</li>
                    <li> for our client through our 360 degree </li>
                    <li> media support. </li>
                    <li>
                      {" "}
                      Starting from Photography, &amp; Videography, Monthly
                      Strategy,
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  {/* <h4 class="title title--h6 title--subtitle js-lines">CONVERSION & NURTURE
                      </h4> */}
                  <ul className="list-unstyled js-block">
                    <li> Content Design, Query Management </li>
                    <li> and Content Management that helps</li>
                    <li> the brand to reach their Target Audience. </li>
                    <li> Thus, through </li>
                    <li>content marketing, we serve retention </li>
                    <li> based client segments. </li>
                  </ul>
                </div>
              </div>
              {/* <a class="btn-link js-lines" href="services.html#">Know More <i class="icon-base icon-right-open"></i></a> */}
            </div>
          </div>
          {/* /itemCard */}
        </div>
        <article id="start" className="caption-singles container">
          <div className="row">
            {/* Intro */}
            <header className="header-page m-t">
              <div className="container">
                {/* Title */}
                <h6 className="title title--overhead title--tail" />
                <h1 className="title title--display-1 js-lines">
                  ANALYTICS &amp; REPORTING
                </h1>
                {/* /Title */}
                <div className="col-12 col-lg-9 m-l">
                  <div className="description noGutters-Bottom js-block">
                    <p className="paragraph noGutters-Bottom">
                      {" "}
                      Social <br />
                      Media Analytics
                      <br />
                      Web Analytics <br />
                      Conversion Analytics
                      <br />
                      Online ROI &amp; Performance Monitoring
                      <br />
                      Ongoing Performance Measurement <br />
                      Proprietary Tools – Google Analytics, Google Data Studio,
                      Brand 24, Social Bakers, HubSpot, SEM Rush, Similar Web,
                      Shutterstock. Mailchimp
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </header>
            {/* /Intro */}
          </div>
        </article>
      </div>
      {/* /Services */}
    </>
  );
};

export default Service;
