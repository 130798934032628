import React from 'react';

const CircleCard = () => {
    return (
        <>
             <div className="container-fluid mb--2">
          <div className="row" id="content">
            <div
              className="circle blue"
              data-aos="zoom-in"
              data-aos-duration="700"
            >
              <h1 className="title title--display-1 js-lines">
                Strategy & Thinking
              </h1>
            </div>
            <div
              className="circles grey"
              data-aos="fade-right"
              data-aos-duration="900"
            >
              <h3 className="title title--h5 itemCard__title js-lines">
                <span>1</span>
                <br />
                Creative & Content{" "}
              </h3>
            </div>
            <div
              className="circles grey"
              data-aos="fade-right"
              data-aos-duration="1300"
            >
              <h3 className="title title--h5 itemCard__title js-lines">
                <span>2</span>
                <br />
                Technology
              </h3>
            </div>
            <div
              className="circles grey"
              data-aos="fade-right"
              data-aos-duration="1600"
            >
              <h3 className="title title--h5 itemCard__title js-lines">
                <span>3</span>
                <br />
                Media
              </h3>
            </div>
            <div
              className="circles grey"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <h3 className="title title--h5 itemCard__title js-lines">
                <span>4</span>
                <br />
                Conversion{" "}
              </h3>
            </div>
            <div
              className="circle blue"
              data-aos="zoom-in"
              data-aos-duration="2600"
            >
              <h1 className="title title--display-1 js-lines">
                Analytics & Reporting
              </h1>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 col-lg-8 offset-col-2"
              style={{ textAlign: "center", margin: "auto" }}
            >
              <a href="/service" target="blank">
                <svg
                  className="liquid-button"
                  data-color1="#a5a5a5"
                  data-color2="#33b5f2"
                  data-color3="#50ebb7"
                  data-text="VIEW ALL SERVICES"
                ></svg>
              </a>
            </div>
          </div>
        </div>
      </>
        
    );
};

export default CircleCard;