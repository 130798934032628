import React from "react";

const Work = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <>
        {/* News */}
        <div className="container">
          <header className="header-page">
            <div className="container">
              {/* Title */}
              <h6 className="title title--overhead title--tail js-lines">
                Our Works.
              </h6>
              <h1 className="title title--display-1 js-lines">
                Relationships we have made &amp; stories we have created.
              </h1>
              {/* /Title */}
            </div>
            <div className="select js-down">
              {/* <span class="placeholder">Select category</span> */}
              <span className="placeholder">Filter</span>
              <ul className="filters">
                <li className="filters__item">
                  <h6 className="title title--overhead filters-title">
                    Filter
                  </h6>
                </li>
                <li className="filters__item active" data-filter="*">
                  <a className="filters__link active" href="#filter">
                    All
                  </a>
                </li>
                <li className="filters__item" data-filter=".retail">
                  <a className="filters__link" href="#filter">
                    Retail{" "}
                  </a>
                </li>
                <li className="filters__item" data-filter=".fintech">
                  <a className="filters__link" href="#filter">
                    Financial{" "}
                  </a>
                </li>
                <li className="filters__item" data-filter=".FMCG">
                  <a className="filters__link" href="#filter">
                    FMCG{" "}
                  </a>
                </li>
                <li className="filters__item" data-filter=".Health">
                  <a className="filters__link" href="#filter">
                    Health
                  </a>
                </li>
                <li className="filters__item" data-filter=".Education">
                  <a className="filters__link" href="#filter">
                    Education{" "}
                  </a>
                </li>
                <li className="filters__item" data-filter=".construction ">
                  <a className="filters__link" href="#filter">
                    Construction{" "}
                  </a>
                </li>
                <li className="filters__item" data-filter=".Electronics">
                  <a className="filters__link" href="#filter">
                    Electronics{" "}
                  </a>
                </li>
                <li className="filters__item" data-filter=".Sport">
                  <a className="filters__link" href="#filter">
                    Sports-Entmt
                  </a>
                </li>
              </ul>
              <input type="hidden" name="changemetoo" />
            </div>
          </header>
          <div className="flex-grid js-masonry-project js-sort">
            {/*1 Post */}
            <figure className="item-project js-block Sport">
              {/* <a href="betking.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Persona</h3>
                <div className="client">Beauty &amp; Parlor.</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136890/Thumnail/Thumbnail_persona_yvwkc9.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/*Post*/}
            {/* Post */}
            <figure className="item-project js-block retail">
              {/* <a href="/spar" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Project Hilsa</h3>
                <div className="client">Restuarant</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136874/Thumnail/thumbnail_hilsa_gofizv.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block Health">
              {/* <a href="medplus.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Pizza 2 Go</h3>
                <div className="client">Restuarant</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136888/Thumnail/Thumbnail_p2g_jd6ey7.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block fintech">
              {/* <a href="fidelitybank.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Purple Patisserie</h3>
                <div className="client">Bakery</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136892/Thumnail/Thumbnail_purple_a3g2zc.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/*Post*/}
            {/* Project */}
            <figure className="item-project js-block fintech">
              {/* <a href="ezipay.html" target="_blank" className="onHover"> */}
              <img
                className="item-news__image lazyload"
                src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674138490/Thumnail/Rainy_Roof_lhyoaa.jpg"
                alt="multiplydigital"
              />
              <figcaption className="onHover__details">
                <h3 className="title title--h5">The Rainy Roof</h3>
                <div className="client">Restuarant</div>
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Project */}
            {/* Post */}
            <figure className="item-project js-block FMCG">
              {/* <a href="kelloggs.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Golden Tulip</h3>
                <div className="client">Hotel</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136871/Thumnail/Thumbnail_gt_nazbho.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block FMCG">
              {/* <a href="so-klin.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Grameen Sweets</h3>
                <div className="client">Sweets & Bakeries</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136869/Thumnail/Thumbnail_gs_magzja.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block retail">
              {/* <a href="/barman" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">GreenLounge</h3>
                <div className="client">Restuarant</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136866/Thumnail/thumbnail_gl_fgthai.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block construction">
              {/* <a href="b5.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Grameen Daimond House</h3>
                <div className="client">Jwellary Shop</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136865/Thumnail/Thumbnail_gdh_zs4gux.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* Post */}
            <figure className="item-project js-block Electronics">
              {/* <a href="prestige.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Fill'iz</h3>
                <div className="client">Restuarant </div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136862/Thumnail/Thumbnail_filliz_leve2a.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block Education">
              {/* <a href="dps.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Exotic </h3>
                <div className="client">Restuarant</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674139545/Thumnail/Exotic_c8phh2.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block FMCG">
              {/* <a href="sonia.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">E Runner</h3>
                <div className="client">Delivery &amp; Service </div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674139818/Thumnail/Erunner_hazulm.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block FMCG">
              {/* <a href="santex.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Dhaka Steak House</h3>
                <div className="client">Restuarant</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136855/Thumnail/thumbnail_DSH_gdm1kz.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* Post */}
            <figure className="item-project js-block Health">
              {/* <a href="vedic-lifecare.html" target="_blank" className="onHover"> */}
              <div className="onHover__details">
                <h3 className="title title--h5">Cherry drops</h3>
                <div className="client">cafeteria</div>
              </div>
              <figcaption className="item-project__figure">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674136849/Thumnail/Thumbnail_cd_v1kro0.jpg"
                  alt="multiplydigital"
                />
              </figcaption>
              {/* </a> */}
            </figure>
            {/* /Post */}
            {/* Project */}
            {/* <figure className="item-project js-block FMCG">
              <a href="so-magic.html" target="_blank" className="onHover">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601743226/webp_files/p8_wpbztb.webp"
                  alt="Project"
                />
                <figcaption className="onHover__details">
                  <h3 className="title title--h5">So Magic</h3>
                  <div className="client">FMCG</div>
                </figcaption>
              </a>
            </figure> */}
            {/* /Project */}
            {/* Post */}
            {/* <figure className="item-project js-block Education">
              <a href="wisconsin.html" target="_blank" className="onHover">
                <div className="onHover__details">
                  <h3 className="title title--h5">Wisconsin</h3>
                  <div className="client">Education</div>
                </div>
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601743227/webp_files/p10_gitjmv.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure> */}
            {/* /Post */}
            {/* Post */}
            {/* <figure className="item-project js-block Electronics">
              <a href="sparki.html" target="_blank" className="onHover">
                <div className="onHover__details">
                  <h3 className="title title--h5">Spark.i</h3>
                  <div className="client">Electronics </div>
                </div>
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601743229/webp_files/p16_id9eqs.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure> */}
            {/* /Post */}
            {/* Post */}
            {/* <figure className="item-project js-block construction">
              <a href="merald.html" target="_blank" className="onHover">
                <div className="onHover__details">
                  <h3 className="title title--h5">Merald</h3>
                  <div className="client">Construction </div>
                </div>
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601743226/webp_files/p4_alxiy4.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure> */}
            {/* /Post */}
            {/* Post */}
            {/* <figure className="item-project js-block FMCG">
              <a href="boom.html" target="_blank" className="onHover">
                <div className="onHover__details">
                  <h3 className="title title--h5">Boom</h3>
                  <div className="client">FMCG</div>
                </div>
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626699026/dmtcaWebpg2021/boom/AnyConv.com__1.Listing_blxlze.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure> */}
            {/* /Post */}
            {/* Post */}
            {/* <figure className="item-project js-block FMCG">
              <a href="django.html" target="_blank" className="onHover">
                <div className="onHover__details">
                  <h3 className="title title--h5">Django </h3>
                  <div className="client">FMCG</div>
                </div>
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626699163/dmtcaWebpg2021/django/AnyConv.com__1.Listing_mlluzp.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure> */}
            {/* /Post */}
            {/* Post */}
            {/* <figure className="item-project js-block Sport">
              <a href="lovefootball.html" target="_blank" className="onHover">
                <div className="onHover__details">
                  <h3 className="title title--h5">Love Football</h3>
                  <div className="client">Sport</div>
                </div>
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626701154/dmtcaWebpg2021/LoveFootball/AnyConv.com__1.Listing_idtzdh.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure> */}
            {/*Post*/}
          </div>
        </div>
        {/* /News */}
      </>
    </div>
  );
};

export default Work;
