import React from "react";

const Barman = () => {
  return (
    <>
      <section className="project-single">
        {/* Intro */}
        <header className="header-page header-fullscreen">
          <div className="wrap-bg">
            <div
              className="js-image js-scale lazyload"
              data-image="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601896075/webp_files/barman/covera_wemp0a.webp"
            />
          </div>
          <div className="container flex-center-end js-opacity">
            {/* Title */}
            <div className="flex-center">
              <div className="flex-center__inner">
                {/* <h6 class="title title--overhead title--tail">Personifying Nigerian's Drinking Culture</h6> */}
                <h1 className="title title--display-1 js-words">
                  Personifying Nigerian's Drinking Culture on Digital{" "}
                </h1>
              </div>
            </div>
            {/* /Title */}
            {/* Details */}
            <div className="case-details flex-end js-down">
              <div className="item-details item-details--single">
                <span className="item-details__title title--overhead">
                  Client
                </span>
                <span className="item-details__text">BarMan</span>
              </div>
              {/* <div class="item-details item-details--single">
						    <span class="item-details__title title--overhead">Year</span>
						    <span class="item-details__text">2019</span>
					    </div> */}
              <div className="item-details item-details--single">
                <span className="item-details__title title--overhead">
                  Industry
                </span>
                <span className="item-details__text">
                  Retail - Liquor Store
                </span>
              </div>
              <div className="item-details item-details--single item-details--end">
                <a
                  className="btn-link btn-link--circle-right"
                  href="https://barman.ng/"
                  target="_blank"
                >
                  See Website
                  <i className="circle circle--white circle--right icon-right-open" />
                </a>
              </div>
            </div>
            {/* /Details */}
          </div>
        </header>
        {/* /Intro */}
        <article id="start" className="caption-singles container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines">About Client </h4>
            </div>
            <div className="col-12 col-lg-12">
              <div className="description js-block">
                <p>
                  The name BarMan comes from the Nigerian term for Bartender.
                  BarMan is a unique and "one-of-its-kind" retail liquor store
                  in Yaba and GRA, Ikjeja. No one else comes close to the
                  quality and experience in product or services. Barman's brand
                  works so well because it's familiar and its connotation is
                  very well understood amongst the locals and directly
                  associated with liquor services.
                </p>
              </div>
            </div>
          </div>
        </article>
        {/* video */}
        <figure className="image-container gallery-two reveal">
          <img
            className="cover lazyload"
            data-src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626697190/dmtcaWebpg2021/barman/AnyConv.com__7.BelowAboutClient_nzvz4y.webp"
            alt=""
          />
        </figure>
        {/* /video */}
        <article className="caption-singles container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines">Challenge</h4>
            </div>
            <div className="col-12 col-lg-12">
              <div className="description js-block">
                <p style={{ marginBottom: 10 }}>
                  The aim was to make BarMan, the chosen Brand when it comes to
                  everything and anything related to distilled spirits and
                  drinks. Being a new brand, as much as they wanted brand
                  awareness they also wanted an increase in walk-ins in their
                  stores followed by sales. They were also looking to sell their
                  products to their target audience online.{" "}
                </p>
                {/* <ul >
	                           <li style="list-style: inside;
							   margin-bottom: 10px;"> They were also looking to sell their products to their target audience  online. </li>
	                           <li style="list-style: inside;
							   margin-bottom: 10px;">Through Digital Marketing, people would come to know that BarMan has got the best brands at the best market price.</li>
	                           <li style="list-style: inside;
							   margin-bottom: 10px;">People will be reminded of what they deserve. It will be a constant reminder that we will offer the best for the best.</li>
	                       </ul>*/}
              </div>
            </div>
          </div>
        </article>
        <article className="caption-singles container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines">Our Solution</h4>
            </div>
            <div className="col-12 col-lg-12">
              <div className="description js-block">
                <p>
                  We raised the Bar with BarMan by crafting a unique digital
                  marketing strategy by identifying the gap in the retail
                  industry especially in the liquor category, segmenting target
                  audience, highlighting behavioral patterns, developing
                  e-commerce websites, other social media assets to appeal to
                  our demographic and using paid media to complement our
                  creative assets.
                </p>
              </div>
            </div>
          </div>
        </article>
        {/* Image */}
        <figure className="image-container container gallery-two">
          <div className="gallery-two__item gallery-two__item--irregular reveal">
            <img
              className="cover lazyload"
              src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626697194/dmtcaWebpg2021/barman/AnyConv.com__10.ImageBelowSolution_1_yoey8n.webp"
              alt=""
            />
          </div>
          <div className="gallery-two__item gallery-two__item--irregular-right reveal">
            {/* video */}
            <video className="video-hero">
              <source
                src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735326/dmtcaAssets/main_wxcsn8.mp4"
                type="video/mp4"
              />
            </video>
            <div className="video-controls">
              <button data-media="play-pause" />
              <button data-media="mute-unmute" />
            </div>
            {/* /video */}
          </div>
        </figure>
        {/* /Image */}
        <article className="caption-single container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines"> Integrated Channels</h4>
            </div>
            <div className="col-6 col-lg-4 inter">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className=" lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735256/dmtcaAssets/ic_uzuwff.svg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="col-lg-9">
                  <p>E commerce Website</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 inter">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className=" lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735225/dmtcaAssets/ic2_qp6nwa.svg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="col-lg-9">
                  <p>Social Media Management</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 inter">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className=" lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735256/dmtcaAssets/ic3_f2qrbn.svg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="col-lg-9">
                  <p>Social Paid Campaigns</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 inter">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className=" lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735326/dmtcaAssets/ic4_hmgifk.svg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="col-lg-9">
                  <p>Paid Media – Display, Social and Search</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 inter">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className=" lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735347/dmtcaAssets/ic5_ak55vz.svg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="col-lg-9">
                  <p>Store Traffic Campaign</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 inter">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className=" lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735257/dmtcaAssets/ic6_kmaokc.svg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="col-lg-9">
                  <p>Influencer Management</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 inter">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    className=" lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735345/dmtcaAssets/ic7_o9ovya.svg"
                    alt=""
                    style={{ width: 60 }}
                  />
                </div>
                <div className="col-lg-9">
                  <p>Conversion Rate Optimization</p>
                </div>
              </div>
            </div>
            {/*	<div class="col-12 col-lg-4 inter">
							<div class="row">
								<div class="col-lg-3"><img class=" lazyload" src="https://dmtca.com/img/barman/ic8.svg" alt="" style="width: 60px;" />
									</div>
								<div class="col-lg-9">
									<p>Paid Media –  Display
									</p>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-4 inter">
							<div class="row">
								<div class="col-lg-3"><img class=" lazyload" src="https://dmtca.com/img/barman/ic8.svg" alt="" style="width: 60px;" />
									</div>
								<div class="col-lg-9">
									<p>Universal App Programmatic  
									</p>
								</div>
							</div>
						</div>*/}
          </div>
        </article>
        <div className="caption-single container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines"> Carousel Ad</h4>
            </div>
          </div>
        </div>
        <article className="caption-singles" style={{ background: "#0051fe" }}>
          <div className="row cars">
            <div className="col-12 col-lg-4 inters">
              <img
                className="cover lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245568/webp_files/barman/s1_kx0pcc_q19o6b.webp"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-4 inters">
              <img
                className="cover lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245568/webp_files/barman/s2_v55tue_bj8ch4.webp"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-4 inters">
              <img
                className="cover lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245568/webp_files/barman/s3_u31meb_wlvh84.webp"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-4 inters">
              <img
                className="cover lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245568/webp_files/barman/s4_nvolpc_b4rbrm.webp"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-4 inters">
              <img
                className="cover lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245568/webp_files/barman/s5_kakrvn_spasy8.webp"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-4 inters">
              <img
                className="cover lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245568/webp_files/barman/s6_wbqrf9_w7xpp3.webp"
                alt=""
              />
            </div>
          </div>
        </article>
        <article className="caption-single container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines">Achievements </h4>
            </div>
            <div className="col-12 col-lg-12">
              <div className="description js-block">
                <p>
                  In the last year, we have successfully created a brand
                  experience with the BarMan Persona. We positioned the BarMan
                  brand digitally as a unique and one-of-its-kind liquor store,
                  with impeccable quality and experience in products and
                  services, and this translated to increased store walk-ins and
                  online orders.{" "}
                </p>
              </div>
            </div>
          </div>
        </article>
        <div id="sales">
          <div className="grid-wrap">
            <div className="row">
              <div className="col-12 col-lg-12 ">
                <div className="sale-item wow fadeInUp">
                  <p>
                    Unique Reach
                    <i className="decrease" />
                  </p>
                  <div>
                    <span>3</span>MN+
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-11 col-md-offset-1">
                <div className="sale-item wow fadeInUp">
                  <p>Impression</p>
                  <div>
                    <span data-count={25}>17.2</span>MN
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-10 col-md-offset-2">
                <div className="sale-item wow fadeInUp">
                  <p>Engagement</p>
                  <div>
                    <span data-count={25}>2</span>MN+
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="row">
	                    <div class="col-xs-12 col-md-9 col-md-offset-3">
	                        <div class="sale-item wow fadeInUp" >
	                            <p>CPM 
	                                </p>
	                            <div><span data-count="25">$</span>1.12</div>
	                        </div>
	                    </div>
	                </div>

	                 <div class="row">
	                    <div class="col-xs-12 col-md-7 col-md-offset-5">
	                        <div class="sale-item wow fadeInUp" >
	                            <p>Enquiries
	                                </p>
	                            <div><span data-count="25"></span>1K+</div>
	                        </div>
	                    </div>
	                </div>
				
					<div class="row">
	                    <div class="col-xs-12 col-md-7 col-md-offset-5">
	                        <div class="sale-item wow fadeInUp" >
	                            <p>People directed to store  </p>
	                            <div><span data-count="854">2</span>K+</div>
	                        </div>
	                    </div>
					</div>
	                <div class="row">
	                    <div class="col-xs-12 col-md-7 col-md-offset-5">
	                        <div class="sale-item wow fadeInUp" >
	                            <p>New Facebook Fans 
	                                </p>
	                            <div><span data-count="25">30</span>K+</div>
	                        </div>
	                    </div>
	                </div>*/}
            <div className="row">
              <div className="col-xs-12 col-md-9 col-md-offset-3">
                <div className="sale-item wow fadeInUp">
                  <p>New Fans &amp; Followers</p>
                  <div>
                    <span data-count={25}>33.5</span>K
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-10 col-md-offset-2">
                <div className="sale-item wow fadeInUp">
                  <p>
                    Store Visits
                    <i className="decrease" />
                  </p>
                  <div>
                    38<span>K</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-11 col-md-offset-1">
                <div className="sale-item wow fadeInUp">
                  <p>
                    Sales out of $2.3k spent
                    <i className="decrease" />
                  </p>
                  <div>
                    <span>$18k</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12 ">
                <div className="sale-item wow fadeInUp">
                  <p>Total Media Spent</p>
                  <div>
                    <span data-count={854}>$18.2</span>K
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12 ">
                <div className="wow fadeInUp">
                  <p>Till Jan 2021</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <article className="caption-single container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines"> Social Media</h4>
            </div>
            {/* <div class="col-12 col-lg-12">
	                        <div class="description js-block">
	                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
	                            </p>
	                        </div>
	                    </div> */}
          </div>
        </article>
        {/* Social */}
        <div className="container">
          <div className="flex-grid js-masonry-project js-sort">
            {/* Project */}
            <figure className="item-project js-block category-design">
              <a className="onHover">
                <img
                  className="item-news__image lazyload"
                  src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626697191/dmtcaWebpg2021/barman/AnyConv.com__17.SM_Static_1_vtrvhm.webp"
                  alt="Project"
                />
              </a>
            </figure>
            {/* /Project */}
            {/* Project */}
            <figure className="item-project js-block category-branding">
              <video className="video-hero" preload="none">
                <source
                  src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735311/dmtcaAssets/v3_ouxowd.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="video-controls">
                <button data-media="play-pause" />
                <button data-media="mute-unmute" />
              </div>
            </figure>
            {/* /Project */}
            {/* Post */}
            <figure className="item-project js-block category-app">
              <a className="onHover">
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601896075/webp_files/barman/m2_fldork.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block category-design">
              <a className="onHover">
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601896075/webp_files/barman/m3_m2wmbn.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure>
            {/* /Post */}
            {/* Post */}
            <figure className="item-project js-block category-branding">
              <video className="video-hero" preload="none">
                <source
                  src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735312/dmtcaAssets/v1_vf3hms.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="video-controls">
                <button data-media="play-pause" />
                <button data-media="mute-unmute" />
              </div>
            </figure>
            {/* /Post */}
            {/* Project */}
            <figure className="item-project js-block category-design">
              <video className="video-hero" preload="none">
                <source
                  src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735313/dmtcaAssets/v4_iz94cv.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="video-controls">
                <button data-media="play-pause" />
                <button data-media="mute-unmute" />
              </div>
            </figure>
            {/* /Project */}
            {/* Post */}
            <figure className="item-project js-block category-app">
              <a className="onHover">
                <figcaption className="item-project__figure">
                  <img
                    className="item-news__image lazyload"
                    src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626697195/dmtcaWebpg2021/barman/AnyConv.com__17.SM_Static_4_1_bkd8wu.webp"
                    alt="Project"
                  />
                </figcaption>
              </a>
            </figure>
            {/* /Post */}
            {/* Project */}
            {/* <figure class="item-project js-block category-branding">
			
					<video class="video-hero" preload="none">
						<source src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735311/dmtcaAssets/v2_q6uq5j.mp4" type="video/mp4">
					</video>
					<div class="video-controls">
						<button data-media="play-pause"></button>
						<button data-media="mute-unmute"></button>
					</div>
	</figure>
	<!-- /Project */}
            {/* Post */}
            {/* <figure class="item-project js-block category-app">
				    <a  class="onHover">
					    
					    <figcaption class="item-project__figure">
						    <img class="item-news__image lazyload" src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626697194/dmtcaWebpg2021/barman/AnyConv.com__17.SM_Static_3_1_obzcwz.webp" alt="Project">
					    </figcaption>
					</a>
				</figure>
				<!-- /Post */}
            {/* Project */}
            <figure className="item-project js-block category-branding">
              <video className="video-hero" preload="none">
                <source src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1626697195/dmtcaWebpg2021/barman/17.SM_Video_1_b7slmo.mp4" />
              </video>
              <div className="video-controls">
                <button data-media="play-pause" />
                <button data-media="mute-unmute" />
              </div>
            </figure>
            {/* /Project */}
            {/* Project */}
            <figure className="item-project js-block category-branding">
              <video className="video-hero" preload="none">
                <source src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1626697202/dmtcaWebpg2021/barman/17.SM_Video_2_biyd8c.mp4" />
              </video>
              <div className="video-controls">
                <button data-media="play-pause" />
                <button data-media="mute-unmute" />
              </div>
            </figure>
            {/* /Project */}
          </div>
        </div>
        {/* /Social */}
        <article className="caption-single container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <span className="num-article num-article--light" />
              <h4 className="title title--h4 js-lines liv"> Live Links </h4>
            </div>
          </div>
          <div className="row socialss">
            <div className="col-12 col-lg-4">
              <h4 className="title title--h4 js-lines">
                <a
                  href="https://www.facebook.com/BarmanNigeria/"
                  target="_blank"
                  className="fac"
                >
                  <img src="assets/img/facebook.svg" />
                  Facebook{" "}
                </a>
              </h4>
            </div>
            <div className="col-12 col-lg-4">
              <h4 className="title title--h4 js-lines">
                <a
                  href="https://www.instagram.com/barman.ng/"
                  target="_blank"
                  className="insta"
                >
                  <img src="assets/img/instagram.svg" />
                  Instagram
                </a>
              </h4>
            </div>
            <div className="col-12 col-lg-3">
              <h4 className="title title--h4 js-lines">
                <a href="https://barman.ng/" target="_blank" className="brand">
                  <img src="assets/img/website.svg" />
                  Website
                </a>
              </h4>
            </div>
            {/*
					<div class="col-12 col-lg-3">
						<h4 class="title title--h4 js-lines"><a class="brand"><img src="https://dmtca.com/img/branding.svg" >App</a></h4>
					    <div class="description js-block">
							<ul>
								<li><a href="https://www.ezipay.africa/ 
									" target="_blank">Website
								</a></li>
								<li><a href="https://ezipaygh.app/
									" target="_blank">Download App
								</a></li>
							</ul>
				        </div>
					</div>
					<div class="col-12 col-lg-3">
						<h4 class="title title--h4 js-lines"><a class="brand"><img src="https://dmtca.com/img/branding.svg" >Other</a></h4>
					    <div class="description js-block">
							<ul>
								<li><a href="https://medium.com/@ezipayGH" target="_blank">Blog 
								</a></li>
								<li><a href="https://www.youtube.com/watch?
v=jw1gB_8jc4c" target="_blank">Youtube
								</a></li>
								
							</ul>
				        </div>
				    </div>*/}
          </div>
        </article>
        <div className="col-12 col-lg-12" style={{ textAlign: "center" }}>
          <a href="works.html" className="btn2">
            See All Works
          </a>
        </div>
      </section>
      {/* /Intro */}
    </>
  );
};

export default Barman;
