import React,{useState,useEffect} from "react";


const Client = () => {
    const [clientData,setClientData]=useState([])

     useEffect(()=>{
         fetch('rawData/clientData.json')
        .then((res)=>res.json())
        .then((data)=>setClientData(data))
     },[])
    
  return (
    <>
      <section className="section mb--10">
        <div className="container">
          <header className="header-page header-page--half mt-p2">
            <div className="container">
              {/* <!-- Title --> */}

              <h1 className="title itle--h1 title--display-1 js-lines">
                Our Clients.
              </h1>
              {/* <!-- /Title --> */}
            </div>
          </header>

          <div className="row os">
            {clientData.map((item) => (
              <div className="col-4 col-md-4 col-lg-3 brand-col js-block">
                {/* <a href="ezipay.html" target="_blank"> */}
                  <img
                    className="brand-col__logo"
                    src={item.image}
                    alt={item.alt}
                  />
                {/* </a> */}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Client;
