import React from "react";
import Client from "../../components/sharedPage/Client";

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header className="header-page header-fullscreen" id="on_mobile">
        <section className="fullsize-video-bg">
          <div id="video-viewport">
            <video
              id="htmlVideo2"
              className="video-hero"
              controls
              preload="display"
            >
              <source
                src="https://res.cloudinary.com/dopcyrucs/video/upload/v1672488263/About_page/About_Us_ltz2fx.mp4"
                type="video/mp4"
              />
            </video>
            <div className="video-controls">
              <button data-media="play-pause"></button>
            </div>
          </div>
        </section>
      </header>

      {/*  <!-- /Intro --> */}
      {/* <!-- Process --> */}
      <article
        id="start"
        className="caption-singles container"
        style={{ paddingBottom: "0px" }}
      >
        <div className="row">
          {/* <!-- Intro --> */}
          <header className="header-page  js-opacity">
            <div className="container">
              {/* 	<!-- Title --> */}
              <h6 className="title title--overhead title--tail js-lines">
                OUR STORY
              </h6>
              <h1 className="title title--display-1 js-lines">
                MULTIPLY DIGITAL - we deliver return oriented solution
              </h1>
              {/* <!-- /Title --> */}
            </div>
          </header>
          {/* <!-- /Intro --> */}
          <div className="col-12 col-lg-9 m-l">
            <div className="description noGutters-Bottom js-block js-lines">
              <p className="paragraph mb--2">
                (Multiply digital) WE ARE DIGITAL CRAFTSPEOPLE, CREATIVE
                THINKERS & STRATEGIC SPECIALISTS. WHERE RETURN IS THE CORE.
              </p>
              <p className="paragraph mb--2">
                We envision a world where people have meaningful experience with
                brands. Day in and day out, we are determined to be your trusted
                partner.
              </p>
            </div>
            <div className="description noGutters-Bottom js-block js-lines">
              <p className="paragraph mb--2">
                Working together, we can make sense of your customer data and
                improve your marketing strategy likewise. We walk in the digital
                galaxy and we can provide 360° marketing solutions for our
                brands.
              </p>
            </div>

            <div className="description noGutters-Bottom js-block js-lines">
              <p className="paragraph mb--2">
                Putting your brand to the center stage, our Digital PR
                specialists have the knowledge on how to get your business in
                front of your audience, securing coverage and links with
                high-ranking titles and trade press.
              </p>
              <p className="paragraph mb--2">
                With a creative engine that breeds idea generation, we build
                bespoke PR campaigns that not only strengthen brand presence but
                accelerate digital performance too.
              </p>
            </div>
          </div>
        </div>
      </article>
      <article
        id="start"
        className="caption-singles container"
        style={{ marginTop: "0px", padding: "0px 20px" }}
      >
        <div className="row">
          <div className="col-12 col-lg-9 m-l">
            <div className="description noGutters-Bottom js-block js-lines">
              {/* <p className="paragraph mb--2">
                DMTCA is an all-inclusive integrated digital marketing agency
                today, with offices and teams in with offices and teams in
                Nigeria, Ghana, Egypt and India, having clients across Africa
                and beyond. From global brands to household brands, from
                healthcare to FMCG, from education to service provider, DMTCA
                has been championing every category. We are growing as we talk.{" "}
              </p> */}
              <p className="paragraph mb--2">
                Now, when we will start working together, we will own your
                business just like you do, if not more. With our insights,
                research, strategy, data, content, creative, technology, we will
                pave way for a sustainable impact. Let’s help you grow a bond
                with your target audience. Let’s grow your revenue. Let’s
                transform your business. Let’s take on challenges. We will do
                our best to make you profitable as well as likable. For us, your
                success will add up to our growth story. Everything is now and
                here. Come, let’s grow together.
              </p>
            </div>
          </div>
        </div>
      </article>
      {/* 	<!-- /Process --> */}

      {/*   <!-- Image --> */}
      <figure className="image-container gallery-two reveal">
        <div className="gallery-two__item" id="image_background"></div>

        <div
          className="gallery-two__item"
          style={{ width: "90%", paddingLeft: "0px", paddingRight: "0px" }}
        >
          <img
            className="cover lazyload"
            src="https://res.cloudinary.com/dopcyrucs/image/upload/v1672488272/About_page/Office_Environment_upists.jpg"
            alt=""
            id="on_mobile"
          />
        </div>
      </figure>
      {/* <!-- /Image --> */}
      {/* 
      <!-- The Story --> */}
      <article id="start" className="caption-singles container">
        <div className="row">
          <div className="col-12 col-lg-3">
            <h4 className="title title--overhead js-lines">WHO WE ARE?</h4>
          </div>
          <div className="col-12 col-lg-9">
            <h2 className="title title--h4 js-lines">
              multiply digital is an Integrated full-service digital marketing
              agency, with office and teams in Bangladesh
            </h2>
            <div className="description noGutters-Bottom js-block js-lines">
              <p className="paragraph noGutters-Bottom">
                We are your trusted partners in a world where people have
                meaningful experience with brands. With a core of 360° marketing
                solutions and return oriented approach our brands can optimize
                your marketing strategy
                {/* <br />
                Whenever you feel, you aren’t getting the desired results; get
                in touch so that we come on board. <br />
                Now when you walk into our DMTCA ecosystem, we will go an extra
                mile to see whether you are achieving what you had head out for.{" "} */}
                {/* <br />
                Thinking out-of-the-box strategies have been our DNA. Creativity
                is our forte. <br />
                Simply put, we are a result-oriented, full service, integrated
                digital marketing agency. */}
              </p>
            </div>
          </div>
        </div>
      </article>

      {/* 		<!-- /The Story --> */}

      {/*   <!-- WeDo --> */}
      <article className="caption-singles container">
        <div className="row">
          <div className="col-12 col-lg-3">
            <h4 className="title title--overhead js-lines">OUR CULTURE</h4>
          </div>
          <div className="col-12 col-lg-9">
            <h2 className="title title--h4 js-lines">
              100 percent creativity. 100 percent individuality.
            </h2>
            <div className="description js-block">
              <p className="paragraph">
                With a team of digital PR specialists, get your business the
                necessary spotlight it needs to thrive in today’s dynamic market
                environment.
                <br />
                Our mission & vision is to build a bespoke brand identity with
                return oriented strategies that
                <br />
                not only strengthens brand presence but accelerates growth as
                well !
              </p>
            </div>
          </div>
        </div>
      </article>

      {/* 	<!-- /WeDo --> */}

      {/* <!-- Brands --> */}
      <Client />

      {/* <!-- /Brands --> */}
    </>
  );
};

export default About;
