import React from "react";

const HeroBanner = () => {
  return (
    <>
      <section className="project-single" style={{ color: "white" }}>
        {/* Intro */}
        <header
          // className="header-page header-fullscreen header-page--gutterBottom overlay overlay--80 jarallaxVideo reveal"
          className="header-page header-fullscreen header-page--gutterBottom jarallaxVideo reveal"
          data-jarallax-video="mp4:https://res.cloudinary.com/www-dmtca-agency/video/upload/v1602248196/team_mtnxbx.mp4"
        >
          <div
            style={{
              minHeight: "100vh",
              width: "100%",
              position: "absolute",
              background: "#000000cc",
            }}
          />
          <div className="container flex-center-end js-opacity">
            {/* Title */}
            <div className="flex-center">
              <div className="flex-center__inner">
                <div className="container">
                  {/* Title */}
                  {/* <h6 class="title title--overhead title--tail">Career</h6> */}
                  <h1 className="title title--display-1 js-lines">
                    We keep the ball moving by constantly adjusting to times.
                  </h1>
                  {/* /Title */}
                </div>
              </div>
            </div>
            {/* /Title */}
          </div>
        </header>
        {/* /Intro */}
      </section>
    </>
  );
};

export default HeroBanner;
