import React from 'react';

const Spar = () => {
    return (
        
            <>
  <section className="project-single">
    {/* Intro */}
    <header className="header-page header-fullscreen "
      style={{ color: "white" }}
    >
      <div className="wrap-bg">
        <div
          className="js-image js-scale lazyload"
          data-image="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245970/webp_files/spar/cover_gc777b_i8y88k.webp"
        />
      </div>
      <div className="container flex-center-end js-opacity">
        {/* Title */}
        <div className="flex-center">
          <div className="flex-center__inner">
            {/* <h6 class="title title--overhead title--tail">Project</h6> */}
            <h1 className="title title--display-1 js-words" style={{color:"#F4AA15"}}>
              Crafting Stories digitally for The Everyday Nigerian{" "}
            </h1>
          </div>
        </div>
        {/* /Title */}
        {/* Details */}
        <div className="case-details flex-end js-down">
          <div className="item-details item-details--single">
            <span className="item-details__title title--overhead">Client</span>
            <span className="item-details__text">SPAR Nigeria</span>
          </div>
          {/* <div class="item-details item-details--single">
					    <span class="item-details__title title--overhead">Year</span>
					    <span class="item-details__text">2019</span>
				    </div> */}
          <div className="item-details item-details--single">
            <span className="item-details__title title--overhead">
              Industry
            </span>
            <span className="item-details__text">Retail - Hypermarket</span>
          </div>
          <div className="item-details item-details--single item-details--end">
            <a
              className="btn-link btn-link--circle-right"
              href="http://phpstack-156292-1219133.cloudwaysapps.com/wp/"
              target="_blank"
            >
              See Website
              <i className="circle circle--white circle--right icon-right-open" />
            </a>
          </div>
        </div>
        {/* /Details */}
      </div>
    </header>
    {/* /Intro */}
    <article id="start" className="caption-singles container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines">About Client </h4>
        </div>
        <div className="col-12 col-lg-12">
          <div className="description js-block">
            <p>
              SPAR is Nigeria's award-winning supermarket and department store
              brand. It offers customers the widest range of products at the
              lowest prices. Spar prides itself in its stores, fresh produce,
              authentic products, and the ability to deliver the best possible
              shopping experience to customers across Nigeria.
            </p>
          </div>
        </div>
      </div>
    </article>
    {/* Image */}
    <figure className="image-container gallery-two reveal">
      <div className="gallery-two__item">
        <img
          className="cover lazyload"
          src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626690019/dmtcaWebpg2021/spar/7.ImageBelowAboutClient_iyedsi.webp"
          alt=""
        />
        <div className="text-overlay text-overlay--left-bottom">Spar</div>
      </div>
      {/* <div class="gallery-two__item"><img class="cover lazyload" src="assets/img/image_project_19.jpg" alt="" /></div> */}
    </figure>
    {/* /Image */}
    <article className="caption-singles container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines">Challenge</h4>
        </div>
        <div className="col-12 col-lg-12">
          <div className="description js-block">
            <p>
              SPAR needed to differentiate its brand from other hypermarkets and
              boost recall amongst consumers. They also wanted to reposition
              their value proposition whillst building communication on the
              pillars of empowerment and education, value add, among other
              things.
            </p>
          </div>
        </div>
      </div>
    </article>
    <article className="caption-singles container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines">Our Solution</h4>
        </div>
        <div className="col-12 col-lg-12">
          <div className="description js-block">
            <p>
              In the last year, we've had success in putting SPAR in the heart
              of Nigerians as an household brand whilst generating organic love
              from our audience. The brand is now seen as one that is for the
              people and this has brought the emergence of organic Brand
              advocates.{" "}
            </p>
          </div>
        </div>
      </div>
    </article>
    {/* Image */}
    <figure className="image-container container gallery-two">
      <div className="gallery-two__item gallery-two__item--irregular reveal">
        <img
          className="cover lazyload"
          src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626690024/dmtcaWebpg2021/spar/10.ImageBelowSolution_psi3pb.webp"
          alt=""
        />
      </div>
      <div className="gallery-two__item gallery-two__item--irregular-right reveal">
        {/* <img class="cover lazyload" src="assets/img/spar/mockup-2.png" alt="" />
				   <div class="text-overlay text-overlay--left-bottom">br</div> */}
        <video className="video-hero" preload="none">
          <source
            src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735243/dmtcaAssets/v_dnqlra.mp4"
            type="video/mp4"
          />
        </video>
        <div className="video-controls">
          <button data-media="play-pause" />
          <button data-media="mute-unmute" />
        </div>
      </div>
    </figure>
    {/* /Image */}
    <article className="caption-single container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines"> Integrated Channels</h4>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735256/dmtcaAssets/ic_uzuwff.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Website Revamp</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735225/dmtcaAssets/ic2_qp6nwa.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Landing Page/Website Conversion Rate Optimization</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735256/dmtcaAssets/ic3_f2qrbn.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Social Media Management</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735326/dmtcaAssets/ic4_hmgifk.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Influencer Management</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735347/dmtcaAssets/ic5_ak55vz.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Video Content Creation and Video Marketing</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735257/dmtcaAssets/ic6_kmaokc.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Search Engine Optimization</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735345/dmtcaAssets/ic7_o9ovya.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Segmented Email Marketing</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735326/dmtcaAssets/ic4_hmgifk.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Blog and content marketing</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735225/dmtcaAssets/ic2_qp6nwa.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Paid Media – Display</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735256/dmtcaAssets/ic3_f2qrbn.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Universal App Programmatic</p>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-4 inter">
          <div className="row">
            <div className="col-lg-3">
              <img
                className=" lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1601735347/dmtcaAssets/ic5_ak55vz.svg"
                alt=""
                style={{ width: 60 }}
              />
            </div>
            <div className="col-lg-9">
              <p>Conversion Rate Optimization</p>
            </div>
          </div>
        </div>
      </div>
    </article>
    {/* Image */}
    <figure className="image-container gallery-two reveal">
      <div className="gallery-two__item">
        <img
          className="cover lazyload"
          src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601905925/webp_files/spar/full-witdh-1_lb0fwc.webp"
          alt=""
        />
        {/* <div class="text-overlay text-overlay--left-bottom">Django</div> */}
      </div>
      <div className="gallery-two__item"></div>
    </figure>
    {/* /Image */}
    <article className="caption-single container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines">Achievements </h4>
        </div>
        <div className="col-12 col-lg-12">
          <div className="description js-block">
            <p>
              At DMTCA, we believe that results should not only be streamlined
              to numbers but felt by clients and our Target Audience. We
              achieved this with SPAR through creating compelling social content
              and online campaigns that brought resounding improvement on equity
              and revenue along with high store traffic.
            </p>
          </div>
        </div>
      </div>
    </article>
    <div id="sales">
      <div className="grid-wrap">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="sale-item wow fadeInUp">
              <p>Unique Reach </p>
              <div>
                <span data-count={854}>27.3</span>MN
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-11 col-md-offset-1">
            <div className="sale-item wow fadeInUp">
              <p>Impression</p>
              <div>
                <span data-count={25}>226.2</span>MN
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-10 col-md-offset-2">
            <div className="sale-item wow fadeInUp">
              <p>Engagement</p>
              <div>
                <span data-count={25}>6</span>MN
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-10 col-md-offset-2">
            <div className="sale-item wow fadeInUp">
              <p>lower CPM</p>
              <div>
                <span data-count={25}>$0.41</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-11 col-md-offset-1">
            <div className="sale-item wow fadeInUp">
              <p>Media Spent</p>
              <div>
                <span data-count={25}>$93.2</span>K
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 ">
            <div className="sale-item wow fadeInUp">
              <p>Sales from Onlines Sales Campaign</p>
              <div>
                <span>$39</span>K
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Image */}
    <figure className="image-container gallery-two reveal">
      <div className="gallery-two__item">
        <img
          className="cover lazyload"
          src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601905926/webp_files/spar/full-witdh-2_pujurk.webp"
          alt=""
        />
        {/* <div class="text-overlay text-overlay--left-bottom">Django</div> */}
      </div>
      {/* <div class="gallery-two__item"><img class="cover lazyload" src="assets/img/image_project_19.jpg" alt="" /></div> */}
    </figure>
    {/* /Image */}
    <article className="caption-single container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines"> Social Media</h4>
        </div>
        {/* <div class="col-12 col-lg-12">
                  <div class="description js-block">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                      </p>
                  </div>
              </div> */}
      </div>
    </article>
    {/* Social */}
    <div className="container">
      <div className="flex-grid js-masonry-project js-sort">
        {/* Project */}
        <figure className="item-project js-block category-design">
          <a className="onHover">
            <img
              className="item-news__image lazyload"
              src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601905926/webp_files/spar/m1_ljevgr.webp"
              alt="Project"
            />
          </a>
        </figure>
        {/* /Project */}
        {/* Project */}
        <figure className="item-project js-block category-branding">
          <video className="video-hero" preload="none">
            <source
              src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735243/dmtcaAssets/v2_wrvy73.mp4"
              type="video/mp4"
            />
          </video>
          <div className="video-controls">
            <button data-media="play-pause" />
            <button data-media="mute-unmute" />
          </div>
        </figure>
        {/* /Project */}
        <figure className="item-project js-block category-app">
          <a className="onHover">
            <figcaption className="item-project__figure">
              <img
                className="item-news__image lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626690122/dmtcaWebpg2021/spar/17.SM_Static_5_l7nkm2.webp"
                alt="Project"
              />
            </figcaption>
          </a>
        </figure>
        {/* Post */}
        {/*<figure class="item-project js-block category-app">
			    <a  class="onHover">
				    
				    <figcaption class="item-project__figure">
					    <img class="item-news__image lazyload" src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626690021/dmtcaWebpg2021/spar/17.SM_Static_3_ompwgt.webp" alt="Project">
				    </figcaption>
				</a>
			</figure>
		    <!-- /Post */}
        {/* Post */}
        {/*<figure class="item-project js-block category-design">
			    <a  class="onHover">
				    
				    <figcaption class="item-project__figure">
					    <img class="item-news__image lazyload" src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601905926/webp_files/spar/m3_sybszc.webp" alt="Project">
				    </figcaption>
				</a>	
			</figure>
		    <!-- /Post */}
        {/* Post */}
        <figure className="item-project js-block category-branding">
          <video className="video-hero" preload="none">
            <source
              src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735243/dmtcaAssets/v3_spmdn4.mp4"
              type="video/mp4"
            />
          </video>
          <div className="video-controls">
            <button data-media="play-pause" />
            <button data-media="mute-unmute" />
          </div>
        </figure>
        {/* /Post */}
        {/* Post */}
        <figure className="item-project js-block category-app">
          <a className="onHover">
            <figcaption className="item-project__figure">
              <img
                className="item-news__image lazyload"
                src="https://res.cloudinary.com/www-dmtca-agency/image/upload/v1626690021/dmtcaWebpg2021/spar/17.SM_Static_3_ompwgt.webp"
                alt="Project"
              />
            </figcaption>
          </a>
        </figure>
        {/* /Post */}
        {/* Project */}
        {/*<figure class="item-project js-block category-design">
			    <a  class="onHover">
				    <img class="item-news__image lazyload" src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1601905927/webp_files/spar/m5_jpmmbh.webp" alt="Project">
				</a>
			</figure>
		    <!-- /Project */}
        {/* Project */}
        <figure className="item-project js-block category-branding">
          <video className="video-hero" preload="none">
            <source
              src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1601735245/dmtcaAssets/v1_vsnpui.mp4"
              type="video/mp4"
            />
          </video>
          <div className="video-controls">
            <button data-media="play-pause" />
            <button data-media="mute-unmute" />
          </div>
        </figure>
        {/* Project */}
        <figure className="item-project js-block category-branding">
          <video className="video-hero" preload="none">
            <source src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1630400523/dmtcaWebpg2021/spar/121325941_876980276263342_8658856305880681806_n_vel2ea.mp4" />
          </video>
          <div className="video-controls">
            <button data-media="play-pause" />
            <button data-media="mute-unmute" />
          </div>
        </figure>
        {/* /Project */}
        {/* Post */}
        <figure className="item-project js-block category-branding">
          <video className="video-hero" preload="none">
            <source src="https://res.cloudinary.com/www-dmtca-agency/video/upload/v1626690132/dmtcaWebpg2021/spar/17.SM_Video_2_-_Copy_vnc5yn.mp4" />
          </video>
          <div className="video-controls">
            <button data-media="play-pause" />
            <button data-media="mute-unmute" />
          </div>
        </figure>
        {/* /Post */}
      </div>
    </div>
    {/* /Social */}
    {/*BLog*/}
    <article className="caption-single container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines">Blog</h4>
        </div>
        <div className="col-12 col-lg-12"></div>
      </div>
    </article>
    {/* Image */}
    <figure className="image-container gallery-two reveal">
      <div className="gallery-two__item">
        <img
          className="cover lazyload"
          src="https://res.cloudinary.com/www-dmtca-agency/image/upload/f_auto/v1602245970/webp_files/spar/blog-1_r9u2s1_hgy0hl.webp"
          alt=""
        />
        {/* <div class="text-overlay text-overlay--left-bottom">Django</div> */}
      </div>
      {/* <div class="gallery-two__item"><img class="cover lazyload" src="assets/img/image_project_19.jpg" alt="" /></div> */}
    </figure>
    {/* /Image */}
    <article className="caption-single container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines">Emailers</h4>
        </div>
        <div className="col-12 col-lg-12"></div>
      </div>
    </article>
    {/* Slider */}
    <div className="slider slider-simply image-container--half reveal">
      <div className="slider-article swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div
              className="cover-slider lazyload"
              data-bg="assets/img/spar/emailers-1.png"
            />
          </div>
          <div className="swiper-slide">
            <div
              className="cover-slider lazyload"
              data-bg="assets/img/spar/emailers-2.png"
            />
          </div>
          <div className="swiper-slide">
            <div
              className="cover-slider lazyload"
              data-bg="assets/img/spar/emailers-3.png"
            />
          </div>
        </div>
      </div>
      <div className="control-slider control-slider--bottom swiper-control">
        <div className="swiper-button-next swiper-button-next--square zoom-cursor">
          <i className="icon-up-open" />
        </div>
        <div className="swiper-button-prev swiper-button-prev--square zoom-cursor">
          <i className="icon-down-open" />
        </div>
      </div>
    </div>
    {/* /Slider */}
    <article className="caption-single container">
      <div className="row">
        <div className="col-12 col-lg-12">
          <span className="num-article num-article--light" />
          <h4 className="title title--h4 js-lines liv"> Live Links </h4>
        </div>
      </div>
      <div className="row socialss">
        <div className="col-12 col-lg-3">
          <h4 className="title title--h4 js-lines">
            <a
              href="https://www.facebook.com/SPAR.NG"
              target="_blank"
              className="fac"
            >
              <img src="assets/img/facebook.svg" />
              Facebook{" "}
            </a>
          </h4>
        </div>
        <div className="col-12 col-lg-3">
          <h4 className="title title--h4 js-lines">
            <a
              href="https://www.instagram.com/spar_nigeria/"
              target="_blank"
              className="insta"
            >
              <img src="assets/img/instagram.svg" />
              Instagram
            </a>
          </h4>
        </div>
        <div className="col-12 col-lg-3">
          <h4 className="title title--h4 js-lines">
            <a
              href="https://twitter.com/SPARNigeria"
              target="_blank"
              className="insta"
            >
              <img src="assets/img/twitter.svg" />
              Twitter
            </a>
          </h4>
        </div>
        <div className="col-12 col-lg-3">
          <h4 className="title title--h4 js-lines">
            <a
              href="http://phpstack-156292-1219133.cloudwaysapps.com/wp/"
              target="_blank"
              className="brand"
            >
              <img src="assets/img/website.svg" />
              Website
            </a>
          </h4>
        </div>
      </div>
    </article>
    <div className="col-12 col-lg-12" style={{ textAlign: "center" }}>
      <a href="works.html" className="btn2">
        See All Works
      </a>
    </div>
  </section>
  {/* /Intro */}
</>

       
    );
};

export default Spar;