import React, { useState, useEffect } from "react";

const TeamShow = () => {
  const [teamData, setTeamData] = useState([]);

  useEffect(()=>{
    fetch('rawData/teamData.json')
   .then((res)=>res.json())
   .then((data)=>setTeamData(data))
},[])
  return (
    <>
      <div class="container">
         {teamData.map((item, index) => (
          <>
            {index % 2 === 0 ? (
              <div class="row row-flex os">
                <div class="col-sm-auto col-lg-5">
                  <div class="block-image">
                    <span class="block-image__number">{item.id}</span>
                    <div class="reveal">
                      <img
                        class=" ls-is-cached lazyloaded reveal"
                        src={item.image}
                        alt={item.alt}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-7">
                  <div class="col-about__describe col-about__describe_right">
                    <h2 class="title title__h2 title__section title_normal">
                      {item.name}
                    </h2>
                    <h6 class=" title__overhead">{item.designation}</h6>
                    <p class="block-description">{item.para}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div class="row row-flex os">
                <div class="col-12 col-lg-7 order-2 order-lg-1">
                  <div class="col-about__describe col-about__describe_left">
                    <h2 class="title title__h2 title__section title_normal">
                      {item.name}
                    </h2>
                    <h6 class=" title__overhead">{item.designation}</h6>
                    <p class="block-description">{item.para}</p>
                  </div>
                </div>
                <div class="col-sm-auto col-lg-5 order-1 order-lg-2">
                  <div class="block-image _right">
                    <span class="block-image__number">{item.id}</span>
                    <div class="reveal">
                      <img
                        class=" ls-is-cached lazyloaded"
                        src={item.image}
                        alt={item.alt}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ))} 
      </div>
    </>
  );
};

export default TeamShow;
