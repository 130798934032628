import React from "react";
import HeroBanner from "../../components/teamPage/HeroBanner";
import TeamShow from "../../components/teamPage/TeamShow";

const Team = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HeroBanner />

      {/* Team */}
      <article className="caption-single container" id="start">
        <div className="row">
          <div className="col-12 col-lg-3">
            <h4 className="title title--overhead js-lines">Our team </h4>
          </div>
          <div className="col-12 col-lg-9">
            <h2 className="title title--h4 js-lines">
              A multi-disciplinary team of creators.
            </h2>
          </div>
          <div className="col-12 col-lg-9 m-l">
            <div className="description noGutters-Bottom js-block">
              <p className="paragraph noGutters-Bottom">
                They say “Talent wins matches but teamwork wins the tournament”.
                So we incorporated BOTH in the workforce ! With a team of
                industry leading talented professionals who has exceptional
                synergy and chemistry We are all set & equipped to Ace any
                assignment & operation.
              </p>
            </div>
          </div>
        </div>
      </article>
      {/* History */}
      <section className="section section-history section_no-space-bottom section_first">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-9 section__header-wrap section__quote">
              <h2 className="title title__h2 title_center title_light title_vertical-line-bottom">
                WORK — is the most democratic of all arts.
              </h2>
            </div>
          </div>
        </div>

        {/* 
        team member showed here */}

        <TeamShow />
      </section>
      {/* /History */}
    </>
  );
};

export default Team;
