import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Barman from "./components/workPage/Barman";
import Spar from "./components/workPage/Spar";
import WebLayout from "./layout/WebLayout";
import About from "./pages/About/About";
import Career from "./pages/Career/Career";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Service from "./pages/Service/Service";
import Team from "./pages/Team/Team";
import Work from "./pages/Work/Work";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<WebLayout />}>
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          <Route forceRefresh={true} element={<Team />} path="/team" />
          <Route element={<Service />} path="/service" />
          <Route element={<Work />} path="/work" />
          <Route element={<Spar />} path="/spar" />
          <Route element={<Barman />} path="/barman" />
          {/* <Route element={<Career />} path="/career" /> */}
          <Route element={<Contact />} path="/contact" />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
