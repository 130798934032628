import React from "react";
import CircleCard from "../../components/homePage/CircleCard";
import Client from "../../components/sharedPage/Client";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <!-- Hero --> */}
      <header className="hero">
        <div className="slider slider-horizontal">
          <div className="slider__caption swiper-container">
            <div className="swiper-wrapper">
              {/* <!-- Caption 1 --> */}

              {/* <!-- Caption 1 --> */}
              <div className="swiper-slide">
                <div className="slider__item">
                  <h1 className="title title--display-1 js-text-wave">
                    Persona
                  </h1>
                  <p className="description">
                    <span className="down-up">
                      <span>
                        Persona has proudly been one of the pioneers of beauty
                        related services in Bangladesh
                      </span>
                    </span>
                  </p>
                  <h6 className="title title--overhead">
                    <span className="down-up">
                      <span>Beauty Parlor </span>
                    </span>
                  </h6>
                  {/* <a
                    className="btn-link btn-link--circle-right"
                    href="fidelitybank.html"
                    target="_blank"
                  >
                    <span className="down-up">
                      <span>
                        See details
                        <i className="circle circle--right icon-right-open"></i>
                      </span>
                    </span>
                  </a> */}
                </div>
              </div>
              {/* <!-- /Caption 1 --> */}

              <div className="swiper-slide">
                <div className="slider__item">
                  <h1 className="title title--display-1 js-text-wave">
                    Project Hilsa
                  </h1>
                  <p className="description">
                    <span className="down-up">
                      <span>
                        {" "}
                        Project Hilsa is a well known Restuarant in Bangladesh
                      </span>
                    </span>
                  </p>
                  <h6 className="title title--overhead">
                    <span className="down-up">
                      <span>Restuarant</span>
                    </span>
                  </h6>
                  {/* <a
                    className="btn-link btn-link--circle-right"
                    href="fidelitybank.html"
                    target="_blank"
                  >
                    <span className="down-up">
                      <span>
                        See details
                        <i className="circle circle--right icon-right-open"></i>
                      </span>
                    </span>
                  </a> */}
                </div>
              </div>

              {/* <!-- Caption 2 --> */}
              <div className="swiper-slide">
                <div className="slider__item">
                  <h1 className="title title--display-1 js-text-wave">
                    Ministry of Commerce
                  </h1>
                  <p className="description">
                    <span className="down-up">
                      <span>
                        The Ministry of Commerce is a ministry of Bangladesh.
                      </span>
                    </span>
                  </p>
                  <h6 className="title title--overhead">
                    <span className="down-up">
                      <span>Ministry</span>
                    </span>
                  </h6>
                  {/* <a
                    className="btn-link btn-link--circle-right"
                    href="spar.html"
                    target="_blank"
                  >
                    <span className="down-up">
                      <span>
                        See details
                        <i className="circle circle--right icon-right-open"></i>
                      </span>
                    </span>
                  </a> */}
                </div>
              </div>
              {/* <!-- /Caption 2 --> */}

              {/* <!-- Caption 3 --> */}

              <div className="swiper-slide">
                <div className="slider__item">
                  <h1 className="title title--display-1 js-text-wave">DNCRP</h1>
                  <p className="description">
                    <span className="down-up">
                      <span>
                        Directorate of National Consumer Rights Protection
                      </span>
                    </span>
                  </p>
                  <h6 className="title title--overhead">
                    <span className="down-up">
                      <span>Government department</span>
                    </span>
                  </h6>
                  {/* <a
                    className="btn-link btn-link--circle-right"
                    href="medplus.html"
                    target="_blank"
                  >
                    <span className="down-up">
                      <span>
                        See details
                        <i className="circle circle--right icon-right-open"></i>
                      </span>
                    </span>
                  </a> */}
                </div>
              </div>
              {/* <!-- /Caption 3--> */}
              {/* <!-- Caption 4 --> */}

              <div className="swiper-slide">
                <div className="slider__item">
                  <h1 className="title title--display-1 js-text-wave">
                    The Green Lounge
                  </h1>
                  <p className="description">
                    <span className="down-up">
                      <span>
                        It is a one of the best and well known Restuarant in bd
                      </span>
                    </span>
                  </p>
                  <h6 className="title title--overhead">
                    <span className="down-up">
                      <span> Restuarant</span>
                    </span>
                  </h6>
                  {/* <a
                    className="btn-link btn-link--circle-right"
                    href="kelloggs.html"
                    target="_blank"
                  >
                    <span className="down-up">
                      <span>
                        See details
                        <i className="circle circle--right icon-right-open"></i>
                      </span>
                    </span>
                  </a> */}
                </div>
              </div>
              {/* <!-- /Caption 4--> */}
              {/* <!-- Caption 5 --> */}

              <div className="swiper-slide">
                <div className="slider__item">
                  <h1 className="title title--display-1 js-text-wave">
                    Purple Patisserie
                  </h1>
                  <p className="description">
                    <span className="down-up">
                      <span>
                      It is a one of the best and well known Patisserie shop in the 
                        city.
                      </span>
                    </span>
                  </p>
                  <h6 className="title title--overhead">
                    <span className="down-up">
                      <span>Patisserie shop</span>
                    </span>
                  </h6>
                  {/* <a
                    className="btn-link btn-link--circle-right"
                    href="so-klin.html"
                    target="_blank"
                  >
                    <span className="down-up">
                      <span>
                        See details
                        <i className="circle circle--right icon-right-open"></i>
                      </span>
                    </span>
                  </a> */}
                </div>
              </div>
              {/* <!-- /Caption 5--> */}
            </div>
          </div>

          <div className="slider__image swiper-container reveal">
            <div className="swiper-wrapper">
              {/* <!-- Image 1 --> */}
              <div className="swiper-slide">
                <div
                  className="cover-slider lazyload overlay--45"
                  data-bg="https://res.cloudinary.com/dopcyrucs/image/upload/v1674564225/Banner_Thumnail/thm_HOME_PAGE_persona_yu4dzq.jpg"
                >
                  {/* <a
                    className="swiper-slide__link"
                    href="fidelitybank.html#"
                  ></a> */}
                </div>
              </div>
              {/* <!-- /Image 1 --> */}

              {/* <!-- Image 1 --> */}
              <div className="swiper-slide">
                <div
                  className="cover-slider lazyload overlay--45"
                  data-bg="https://res.cloudinary.com/dopcyrucs/image/upload/v1674564222/Banner_Thumnail/thm_HOME_PAGE_hilsa_fl5yop.jpg"
                >
                  {/* <a className="swiper-slide__link" href="betking.html#"></a> */}
                </div>
              </div>
              {/* <!-- /Image 1 --> */}

              {/* <!-- Image 2 --> */}
              <div className="swiper-slide">
                <div
                  className="cover-slider lazyload overlay--45"
                  data-bg="https://res.cloudinary.com/dopcyrucs/image/upload/v1674564224/Banner_Thumnail/thm_HOME_PAGE_ministry_ad5eh9.jpg"
                >
                  {/* <a className="swiper-slide__link" href="spar.html"></a> */}
                </div>
              </div>
              {/* <!-- /Image 2 --> */}

              {/* <!-- Image 3 --> */}

              <div className="swiper-slide">
                <div
                  className="cover-slider lazyload overlay--45"
                  data-bg="https://res.cloudinary.com/dopcyrucs/image/upload/v1674566169/Banner_Thumnail/thm_HOME_PAGE_dncrp_lbbqly.jpg"
                >
                  {/* <a className="swiper-slide__link" href="medplus.html"></a> */}
                </div>
              </div>
              {/* <!-- /Image3 --> */}
              {/* <!-- Image 4 --> */}
              <div className="swiper-slide">
                <div
                  className="cover-slider lazyload overlay--45"
                  data-bg="https://res.cloudinary.com/dopcyrucs/image/upload/v1674564220/Banner_Thumnail/thm_HOME_PAGE_gl_vqfzza.jpg"
                >
                  {/* <a className="swiper-slide__link" href="kelloggs.html"></a> */}
                </div>
              </div>
              {/* <!-- /Image 4 --> */}
              {/* <!-- Image 5 --> */}
              <div className="swiper-slide">
                <div
                  className="cover-slider lazyload overlay--45"
                  data-bg="https://res.cloudinary.com/dopcyrucs/image/upload/v1674564230/Banner_Thumnail/thm_HOME_PAGE_purple_jnjhr6.jpg"
                >
                  {/* <a className="swiper-slide__link" href="so-klin.html"></a> */}
                </div>
              </div>
              {/* <!-- /Image 5 --> */}
            </div>
          </div>

          {/* <!-- Control --> */}
          <div className="control-slider control-slider--vertical swiper-control">
            <div></div>
            <div className="swiper-pagination"></div>
            <div>
              <div className="swiper-button-next zoom-cursor">
                <svg
                  className="slider-nav slider-nav--progress"
                  viewBox="0 0 46 46"
                >
                  <circle
                    className="slider-nav__path-progress slider-nav__path-progress--gray"
                    cx="23"
                    cy="23"
                    r="22.5"
                  />
                </svg>
                <svg
                  className="slider-nav slider-nav--gray"
                  viewBox="0 0 46 46"
                >
                  <circle
                    className="slider-nav__path--gray"
                    cx="23"
                    cy="23"
                    r="22.5"
                  />
                  <path
                    className="slider-nav__arrow"
                    d="M26.45 22.45l-4.91-4.91a.7707464.7707464 0 0 0-1.09 1.09L24.82 23l-4.36 4.36a.7707464.7707464 0 0 0 1.09 1.09l4.91-4.91a.77.77 0 0 0-.01-1.09z"
                  />
                </svg>
              </div>
              <div className="swiper-button-prev zoom-cursor">
                <svg
                  className="slider-nav slider-nav--gray"
                  viewBox="0 0 46 46"
                >
                  <circle
                    className="slider-nav__path--gray"
                    cx="23"
                    cy="23"
                    r="22.5"
                  />
                  <path
                    className="slider-nav__arrow"
                    d="M18.5 23.55l4.91 4.91a.7707464.7707464 0 1 0 1.09-1.09L20.14 23l4.36-4.36a.7707464.7707464 0 0 0-1.09-1.09l-4.91 4.9a.77.77 0 0 0 0 1.1z"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* <!-- /Control --> */}

          <div className="social social--floating">
            <a
              className="social__link"
              href="https://www.linkedin.com/company/thedmtca/"
              target="blank"
            >
              <i className="fa fa-linkedin fa-1x" aria-hidden="true"></i>
            </a>
            <a
              className="social__link"
              href="https://www.facebook.com/thedmtca/"
              target="_blank"
            >
              <i className="fa fa-facebook fa-1x" aria-hidden="true"></i>
            </a>

            <a
              className="social__link"
              href="https://www.instagram.com/thedmtca/"
              target="_blank"
            >
              <i className="fa fa-instagram fa-1x" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </header>
      {/* <!-- /Hero --> */}
      {/* <!-- Hello --></div> */}
      <section
        className="section section__hello section_no-space-bottom"
        id="hello"
        style={{ marginBottom: "4.4rem" }}
      >
        <div className="container">
          <div className="box-image">
            <div className="reveal">
              <img
                className="lazyload"
                data-src="https://res.cloudinary.com/dopcyrucs/image/upload/v1674284907/Homepage/homeImg_rokry2.jpg"
                alt="Hello!"
              />

              <a href="#" className="ripple-block">
                <div className="ripple ripple-1"></div>
                <div className="ripple ripple-2"></div>
                <div className="ripple ripple-3"></div>
              </a>
              <a href="#popup3" className="playBut">
                {/* <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In  --> */}
                <svg
                  version="1.1"
                  x="0px"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  xmlnsA="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                  y="0px"
                  width="120px"
                  height="120px"
                  viewBox="0 0 213.7 213.7"
                  enableBackground="new 0 0 213.7 213.7"
                  xmlSpace="preserve"
                >
                  <polygon
                    className="triangle"
                    id="XMLID_18_"
                    fill="none"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="73.5,62.5 148.5,105.8 73.5,149.1 "
                  />

                  <circle
                    className="circles"
                    id="XMLID_17_"
                    fill="none"
                    strokeWidth="7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    cx="106.8"
                    cy="106.8"
                    r="103.3"
                  />
                </svg>{" "}
              </a>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-12 col-lg-8 col__quote text-center  js-opacity"
              data-bottom="transform[swing]:translateY(0px)"
              data--400-top="transform[swing]:translateY(-100px)"
            >
              {/* <!-- <h2 className="title__section title__h1 title_decoration title_vertical-line-top js-lines">Hello.</h2> --> */}
              <blockquote className="block-quote block-quote__about js-block">
                <p className="description p ">
                  Multiply Digital - We envision a world where people have
                  meaningful experience with brands. Day in and day out, we are
                  determined to be your trusted partner. Working together, we
                  can make sense of your customer data and improve your
                  marketing strategy likewise......
                  <a
                    className="decoration--none triger-hl"
                    href="about.html"
                    target="blank"
                  >
                    <span
                      className="hover-line"
                      style={{ fontWeight: 500, fontSize: "19px" }}
                    >
                      Read More
                    </span>
                  </a>
                </p>
              </blockquote>
            </div>
          </div>
        </div>
        <div
          className="text-decoration"
          data-100-start="transform[swing]:translateY(100px)"
          data--800-top="transform[swing]:translateY(-100px)"
        >
          About Us
        </div>
      </section>
      {/* <!-- /Hello --> */}

      <div id="popup3" className="overlay3">
        <div className="popup3">
          <a className="close3" href="#">
            &times;
          </a>
          <div className="content">
            <video
              id="htmlVideo2"
              controls
              allow="autoplay"
              src="https://res.cloudinary.com/dopcyrucs/video/upload/v1663674958/video/MD_Logo_cjepuy.mp4"
            ></video>
          </div>
        </div>
      </div>
      {/* <!-- Services --> */}
      <div className="container-fluid mb--2">
        {/* <!-- Process --> */}
        <article id="start" className="caption-singles container">
          <div className="row">
            {/* <!-- Intro --> */}
            <header className="header-page  js-opacity mt-p">
              <div className="container">
                {/* <!-- Title --> */}
                <h6 className="title title--overhead title--tail">
                  What We Do.
                </h6>
                <h1 className="title title--display-1 js-lines">
                  We are what we repeatedly do. Excellence, then, is not an act,
                  but a habit.
                </h1>
                {/* <!-- /Title --> */}
              </div>
            </header>
            {/* <!-- /Intro --> */}
            <div className="col-12 col-lg-9 m-l">
              <div className="description noGutters-Bottom js-block">
                <p className="paragraph noGutters-Bottom">
                  Multiply Digital exhibit your product with a radiance that
                  helps you illustrate the sublime qualities of your business.
                  With a creative team who possess an exceptional skill set we
                  <strong
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      letterSpacing: "1px",
                      color: "#56b7be",
                    }}
                  >
                    {" "}
                    generate meaningful app, web and static content
                  </strong>{" "}
                  that really showcases your product in an extraordinary manner.
                </p>
              </div>
            </div>
          </div>
        </article>
        {/* <!-- /Process --> */}
        <CircleCard />
      </div>
      {/* <!-- /Services --> */}

      {/*<!-- Brands --> */}
      <Client />
      {/* <!-- /Brands --> */}
    </>
  );
};

export default Home;
