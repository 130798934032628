import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
/* import { CircleArrow as ScrollUpButton } from "react-scroll-up-button"; */

const WebLayout = () => {
  let navigate = useNavigate();

  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true });
    window.location.reload();
  }

  return (
    <>
      {/* <!-- Preloader --> */}
      <div className="preloader">
        <div className="preloader__wrap">
          <img
            className="preloader__logo"
            src="https://res.cloudinary.com/dopcyrucs/image/upload/v1663677475/clients/logo-white_uqeiss.png"
            alt="ARTEM"
          />
          <div className="preloader__progress">
            <span></span>
          </div>
        </div>
      </div>

      {/* <!-- Top --> */}
      <nav className="navbar">
        <div className="mr-auto logo-container">
          {/*           <a  href="/"> */}
          <Link
            to="/"
            className="logo-link"
            onClick={() => changeLocation("/")}
          >
            <img
              className="logotype logotype__front"
              src="https://res.cloudinary.com/dopcyrucs/image/upload/v1663677475/clients/logo-white_uqeiss.png"
              alt="DMTCA"
            />
            <img
              className="logotype logotype__back"
              src="https://res.cloudinary.com/dopcyrucs/image/upload/v1663677475/clients/logo-white_uqeiss.png"
              alt="DMTCA"
            />
          </Link>

          {/*  </a> */}
        </div>
        <div className="ml-sm-auto">
          <button className="hamburger zoom-cursor" type="button">
            <span className="hamburger__inner"></span>
          </button>
        </div>
      </nav>
      {/* <!-- /Top --> */}
      <Outlet />
      {/*<!-- Footer --> */}
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 footer__left">
              <div>
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-9 section__header-wrap">
                    <h2
                      className="title title__h2 title_center title_normal js-block"
                      style={{ fontWeight: "100" }}
                    >
                      <span className="">BUILDING THE BRAND.</span>
                    </h2>
                    <p
                      style={{
                        color: "#000",
                        letterSpacing: "1px",
                        textAlign: "center",
                      }}
                    >
                      {/* We are experienced and efficient when it comes to building
                      up a brand from scratch or making significant improvements
                      to its online presence. With a creative & qualified team,
                      we ensure a distinguished momentum for your brand. */}
                      We MULTIPLY DIGITAL envision a marketing world where
                      return on your each investment is the core ! An effective
                      return-based brand today, can have a positive effect on
                      your target market and searing a psychological image into
                      their minds that can last lifetime, Set you apart from the
                      herd
                    </p>
                  </div>
                </div>

                <div className="form-group">
                  <form className="subscribe-form" data-toggle="validator">
                    <div className="row">
                      <div className="col-12" style={{ textAlign: "center" }}>
                        <a id="open-popup" className="btn2">
                          GET IN TOUCH
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 footer__right">
              <div className="row">
                <ul className="col-12 col-md-3 col-lg-3 col-xl-3 listContact list-unstyled js-lines">
                  <li>
                    <h6 className="title title--h6">MULTIPLY DIGITAL</h6>
                  </li>
                  <li>
                    <strong></strong>
                    <p style={{ marginBottom: "0px" }}>
                      457, 3rd Floor, DIT Road, West &amp;
                      <p>Rampura, Dhaka-1219</p>
                    </p>
                  </li>
                </ul>

                <ul className="col-12 col-md-2 col-lg-2 col-xl-2 listContact list-unstyled js-lines">
                  {/* <li>
                    <h6 className="title title--h6">GHANA OFFICE</h6>
                  </li> */}
                  <li style={{ marginBottom: "10px" }}>
                    <a
                      className="link_decoration"
                      href="info@multiplydigital.net"
                    >
                      info@multiplydigital.net
                    </a>
                  </li>
                  <li>
                    <a className="link_decoration" href="Tel:+8801768729111">
                      (+880) 1768729111
                    </a>
                  </li>
                </ul>

                <ul className="col-12 col-md-2 col-lg-2 col-xl-2 listContact list-unstyled js-lines">
                  {/* <li>
                    <h6 className="title title--h6">EGYPT OFFICE</h6>
                  </li> */}
                  <li>
                    <Link
                      to="/about"
                      className="link_decoration"
                      onClick={() => changeLocation("/about")}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team"
                      className="link_decoration"
                      onClick={() => changeLocation("/team")}
                    >
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/service"
                      className="link_decoration"
                      onClick={() => changeLocation("/service")}
                    >
                      Services
                    </Link>
                  </li>
                </ul>

                <ul className="col-12 col-md-2 col-lg-2 col-xl-2 listContact list-unstyled js-lines">
                  {/* <li>
                    <h6 className="title title--h6">QUICK LINKS</h6>
                  </li> */}

                  <li>
                    <Link
                      to="/work"
                      className="link_decoration"
                      onClick={() => changeLocation("/work")}
                    >
                      Works
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/career"
                      className="link_decoration"
                      onClick={() => changeLocation("/career")}
                    >
                      Career
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/contact"
                      className="link_decoration"
                      onClick={() => changeLocation("/contact")}
                    >
                      Contact us
                    </Link>
                  </li>
                </ul>
                {/* <div className="addon">
                  <a
                    href="https://www.google.com/partners/agency?id=2803884927"
                    target="_blank"
                  >
                    <img src="https://www.gstatic.com/partners/badge/images/2021/PartnerBadgeClickable.svg" />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ borderTop: "1px solid #eaeaea" }}>
          <div className="footer-middle col-12 col-lg-8">
            <div className="copyright">
              <a href="https://www.multiplydigital.net" target="blank">
                {" "}
                &copy; MULTIPLY DIGITAL 2023.
              </a>{" "}
              All Rights Reserved.
            </div>
          </div>
          <div className="socials-wrap disable-drag col-12 col-lg-4">
            {" "}
            <div className="socials-icon">
              <i className="fa fa-share-alt" aria-hidden="true"></i>
            </div>
            <div className="socials-text">Follow Us</div>
            <div className="ex2">
              <a
                href="https://www.linkedin.com/company/thedmtca/"
                target="blank"
              >
                <i aria-hidden="true" className="fa fa-linkedin"></i>
              </a>
              <a href="https://www.facebook.com/thedmtca/" target="_blank">
                <i className="fa fa-facebook fa-2x" aria-hidden="true"></i>
              </a>
              <a href="https://twitter.com/thedmtca" target="_blank">
                <i className="fa fa-twitter fa-2x" aria-hidden="true"></i>
              </a>
              <a href="https://www.instagram.com/thedmtca/" target="blank">
                <i className="fa fa-instagram fa-2x" aria-hidden="true"></i>
              </a>

              <a
                href="https://www.youtube.com/channel/UCl29mBA86KG0R66IKNQcwkQ"
                target="blank"
              >
                <i aria-hidden="true" className="fa fa-youtube-play"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- /Footer -->	 */}

      {/*  <a href="#" id="scroll" style={{ display: "none" }}>
        <i className="circle circle--white circle--right icon-up-open"></i>
      </a>  */}
      {/* 
      <ScrollUpButton style={{left:10,bottom:50}}/> */}
      {/* <!-- Menu --> */}
      <div className="menu">
        <div className="menu-inner">
          <ul className="menu-list">
            <li>
              <Link
                to="/about"
                className="menu-list__item"
                onClick={() => changeLocation("/about")}
              >
                about
              </Link>
            </li>
            <li>
              <Link
                to="/team"
                className="menu-list__item"
                onClick={() => changeLocation("/team")}
              >
                team
              </Link>
            </li>
            <li>
              <Link
                to="/service"
                className="menu-list__item"
                onClick={() => changeLocation("/service")}
              >
                services
              </Link>
            </li>
            <li>
              <Link
                to="/work"
                className="menu-list__item"
                onClick={() => changeLocation("/work")}
              >
                works
              </Link>
            </li>
            {/* <li>
              <Link
                to="/career"
                className="menu-list__item"
                onClick={() => changeLocation("/career")}
              >
                career
              </Link>
            </li> */}
            <li>
              <Link
                to="/contact"
                className="menu-list__item"
                onClick={() => changeLocation("/contact")}
              >
                contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="social social--white">
          <a
            className="social__link"
            href="https://www.linkedin.com/company/thedmtca/"
            target="blank"
          >
            <i className="fa fa-linkedin fa-1x" aria-hidden="true"></i>
          </a>
          <a
            className="social__link"
            href="https://www.facebook.com/thedmtca/"
            target="_blank"
          >
            <i className="fa fa-facebook fa-1x" aria-hidden="true"></i>
          </a>

          <a
            className="social__link"
            href="https://www.instagram.com/thedmtca/"
            target="_blank"
          >
            <i className="fa fa-instagram fa-1x" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div className="ef-background"></div>
      {/* <!-- /Menu --> */}
      {/* <!-- Contact Form --> */}
      <div className="popup popup-overlay">
        <button
          id="close-popup"
          className="popup__btn-close"
          aria-label="Close"
        >
          close
        </button>
        <div className="popup-inner">
          {/* <!-- Form --> */}
          <h2 className="title title--h3 text-lg-center js-lines">
            <div
              style={{
                display: "block",
                textAlign: "center",
                position: "relative",
                opacity: 1,
                transform: "matrix(1, 0, 0, 1, 0, 0)",
              }}
            >
              Let Us Help You Grow Through Digital
            </div>
          </h2>

          <form
            id="contact-form"
            className="contact-form"
            data-toggle="validator"
            noValidate="true"
          >
            <div className="container container_md">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="inputText"
                      id="frmname"
                      name="frmname"
                      placeholder="Full Name"
                      required="required"
                      autocomplete="off"
                      onInvalid="setCustomValidity('Fill in the field')"
                      onKeyUp="setCustomValidity('')"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="inputText"
                      id="frmemail"
                      name="frmemail"
                      placeholder="Email Address"
                      required="required"
                      autocomplete="off"
                      onInvalid="setCustomValidity('Email is incorrect')"
                      onKeyUp="setCustomValidity('')"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="inputText"
                      id="frmphone"
                      name="frmphone"
                      placeholder="Phone Number"
                      required="required"
                      autoComplete="off"
                      onInvalid="setCustomValidity('Fill in the field')"
                      onKeyUp="setCustomValidity('')"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="inputText"
                      id="frmlocation"
                      name="frmlocation"
                      placeholder="Present Location 
			"
                      required="required"
                      autocomplete="off"
                      onInvalid="setCustomValidity('Fill in the field')"
                      onKeyUp="setCustomValidity('')"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="inputText"
                      id="frmdesignation"
                      name="frmdesignation"
                      placeholder="Your Position in Company"
                      required="required"
                      autocomplete="off"
                      onInvalid="setCustomValidity('Fill in the field')"
                      onKeyUp="setCustomValidity('')"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      pattern="((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in)(\/[^\s]*)?"
                      className="inputText"
                      id="frmwebsite"
                      name="frmwebsite"
                      placeholder="Company Website"
                      required="required"
                      autocomplete="off"
                      onInvalid="setCustomValidity('Website url not valid. ')"
                      onKeyUp="setCustomValidity('')"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="inputText"
                      id="frmmessage"
                      name="frmmessage"
                      placeholder="Your Message"
                      required="required"
                      autocomplete="off"
                      onInvalid="setCustomValidity('Fill in the field')"
                      onKeyUp="setCustomValidity('')"
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div
                  className="col-12 col-md-12"
                  style={{ textAlign: "center" }}
                >
                  <button type="submit" className="btn2 disabled">
                    Send Message
                  </button>
                </div>
                <div className="col-12 col-md-12 text-md-right">
                  <div id="validator-contact" className="_hidden">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* <!-- /Form --> */}
        </div>
      </div>
      {/* <!-- /Contact Form --> */}

      {/* <div className="contact-icon">
        <a className="action" href="works.html">
          <img
            src="../res.cloudinary.com/www-dmtca-agency/image/upload/v1631723961/dmtcaWebpg2021/w_on_b_h47gjb.png"
            alt="contact"
          />
        </a>
      </div> */}

      <div className="cursor"></div>
    </>
  );
};

export default WebLayout;
